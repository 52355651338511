// src/components/BannerSlider.js
import React from "react";
import { Carousel, Accordion } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"; // Import Contentful rich text renderer
import useContentful from "../../hooks/useContentful";
import "./BannerSlider.css";
import { get } from "lodash";
import { getPath } from "../../services/Utils";

const BannerSlider = () => {
  // Fetching banner and homepageAlerts content
  const {
    items: banners,
    loading: bannerLoading,
    error: bannerError,
  } = useContentful("banner");
  const {
    items: alerts,
    loading: alertsLoading,
    error: alertsError,
  } = useContentful("alerts");
  const {
    items: contentPages,
    loading: pagesLoading,
    error: pagesError,
  } = useContentful("contentPage");
  console.log("Document", contentPages[0]); // Fetch content pages

  const navigate = useNavigate(); // Hook for navigation

  if (bannerLoading || alertsLoading || pagesLoading)
    return <div>Loading...</div>;
  if (bannerError)
    return <div>Error loading banners: {bannerError.message}</div>;
  if (alertsError)
    return <div>Error loading alerts: {alertsError.message}</div>;
  if (pagesError)
    return <div>Error loading content pages: {pagesError.message}</div>;

  if (!Array.isArray(banners) || banners.length === 0) {
    return <div>No banners available</div>;
  }
  if (!banners || !Array.isArray(banners) || banners.length === 0) {
    console.log("No banners available - banners data:", banners);
    return <div>No banners available</div>;
  }
  

  // Function to extract short description from rich text
  const extractShortDescription = (richTextContent) => {
    if (Array.isArray(richTextContent)) {
      return richTextContent
        .slice(0, 1)
        .map((contentItem, index) => (
          <div key={index}>{documentToReactComponents(contentItem)}</div>
        ));
    }
    return null;
  };

  // Function to render tabs content
  const renderTabs = (tabs) => {
    return tabs?.map((tab, tabIndex) => (
      <li key={tabIndex}>
        <strong>
          <Link to={"/alerts"}>{tab.fields.title}:</Link>
        </strong>
        <div>
          {extractShortDescription(tab.fields.alertsDescription.content)}
        </div>
      </li>
    ));
  };

  // Handle alert click to navigate to AlertsPage
  const handleAlertClick = (tab, tabIndex) => {
    const description = tab.fields.alertsDescription.content.map(
      (contentItem) => contentItem.content[0].value
    );

    navigate("/alerts", {
      state: {
        alerts: [
          {
            title: tab.fields.title,
            description,
          },
        ],
        activeTab: tabIndex, // Pass the active tab index
      },
    });
  };

  const handleBannerClick = (banner) => {
    const route = `/${banner.fields.url}`;
    console.log("Clicked Banner:", banner.fields.title);
    console.log("Navigating to:", route);
    
    if (!route || route === "/") {
      console.error("Invalid URL for banner:", banner);
      return;
    }
  
    navigate(route);
  };
  
  

  // Get recent updated content pages
  const recentUpdates = contentPages
    .sort((a, b) => new Date(b.sys.updatedAt) - new Date(a.sys.updatedAt)) // Sort by updatedAt
    .slice(0, 5) // Get the latest 5
    .map((page) => {
      const contentDescription =
        page.fields.Contentdescription?.content[0]?.content[0]?.value;
      const ContentTitle = page.fields.slug;
      console.log(ContentTitle, "tets");

      const parent = get(
        page,
        "fields.parentSubMenu.fields.parentMenu.fields.slug",
        null
      );

      // console.log("parent", parent);

      const Fetchpath = getPath(ContentTitle, parent);
      //  console.log(Fetchpath);

      return (
        <li key={page.sys.id}>
          <strong>
            <Link to={`${Fetchpath}`}>
              {page.fields.title || "Untitled Page"}
            </Link>
          </strong>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {contentDescription}
          </div>
        </li>
      );
    });

  return (
    <div className="banner-container">
      {/* Carousel Section */}
      <Carousel>
  {banners.map((banner) => (
    <Carousel.Item key={banner.sys.id}>
      <img
        className="d-block w-100"
        src={banner.fields.image.fields.file.url}
        alt={banner.fields.title}
        onClick={() => handleBannerClick(banner)} // Pass banner object
        style={{ cursor: "pointer" }}
      />
      <Carousel.Caption>
        <p>{banner.fields.description}</p>
      </Carousel.Caption>
    </Carousel.Item>
  ))}
</Carousel>


      {/* Alerts Box with Accordion */}
      <div
        className="alerts-box"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <Accordion defaultActiveKey="0">
          {alerts.length > 0 &&
            alerts.map((alertItem, index) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={alertItem.sys.id}
              >
                <Accordion.Header>
                  {alertItem.fields.title || "Alerts"}
                </Accordion.Header>
                <Accordion.Body style={{ maxHeight: '260px', overflowY: 'auto' }}>
                  <ul>{renderTabs(alertItem.fields.tabs)}</ul>
                  <button
                    onClick={() =>
                      handleAlertClick(alertItem.fields.tabs[0], 0)
                    }
                  >
                    View More...
                  </button>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          <Accordion.Item>
            <Accordion.Header>Document Updates</Accordion.Header>
            <Accordion.Body>
              <ul>
                {recentUpdates.length > 0 ? (
                  recentUpdates
                ) : (
                  <li>No recent updates available.</li>
                )}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default BannerSlider;
