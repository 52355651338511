import React, { useState, useEffect, useRef } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Form,
  FormControl,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import useGroupedContent from "../../hooks/useGroupedContent";
import "./NavigationBar.css";
import PreTicketSearch from "../PreTicketSearch/PreTicketSearch";

const NavigationBar = ({ onValueChange }) => {
  // const mainContent = useGroupedContent("nmopsPortal")[0]?.fields?.mainMenu || [];
  const groupedContent =
    useGroupedContent("nmopsPortal")[0]?.fields?.mainMenu || [];
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [activePath, setActivePath] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const divRef = useRef(null); // Create a ref for the specific div
  const updateDimensions = () => {
    // Calculate the height of the particular div
    setTimeout(function () {
      const divHeight = divRef.current
        ? divRef.current.getBoundingClientRect().height
        : 0;

      // Set the new dimensions to state
      onValueChange(divHeight + 100); // Update the parent with the div height
      console.log(divHeight, "divHeight");
    }, 1000);
  };

  // Manually trigger recalculation of div height on button click
  const handleButtonClick = () => {
    console.log("Button clicked, updating dimensions");
    updateDimensions(); // Trigger the dimensions update
  };

  useEffect(() => {
    setActivePath(location.pathname);
    updateOpenKeys(location.pathname);
  }, [location, groupedContent]);

  useEffect(() => {
    console.log("Fetched Grouped Content:", groupedContent);
    // Add a check before running forEach
    if (groupedContent && groupedContent.length > 0) {
      groupedContent.forEach((mainMenu) => {
        console.log("Main Menu:", mainMenu.fields.title);
        // Add check for subMenus
        if (mainMenu.fields.subMenus && mainMenu.fields.subMenus.length > 0) {
          mainMenu.fields.subMenus.forEach((subMenu) => {
            console.log("  Sub Menu:", subMenu.fields.title);
            // Add check for contentPages
            if (
              subMenu.fields.contentPages &&
              subMenu.fields.contentPages.length > 0
            ) {
              subMenu.fields.contentPages.forEach((contentPage) => {
                console.log("    Content Page:", contentPage.fields.title);
              });
            }
          });
        }
      });
    }
  }, [groupedContent]);

  const updateOpenKeys = (path) => {
    const keys = [];
    let currentPath = "";
    path.split("/").forEach((segment) => {
      if (segment) {
        currentPath += `/${segment}`;
        keys.push(currentPath);
      }
    });
    setOpenKeys(keys);
  };

  const handleMouseEnter = (menuKey) =>
    setDropdownOpen((prev) => ({ ...prev, [menuKey]: true }));
  const handleMouseLeave = (menuKey) =>
    setDropdownOpen((prev) => ({ ...prev, [menuKey]: false }));
  const handleItemClick = (menuKey) =>
    setDropdownOpen((prev) => ({ ...prev, [menuKey]: false }));


  const renderSubMenu = (subMenu, parentPath, level = 0) => {
    const currentPath = `${parentPath}/${subMenu.fields?.slug}`;
    const isOpen = openKeys.includes(currentPath);
    const isActive = activePath.startsWith(currentPath);

    const hasContentPages =
      subMenu.fields.contentPages && subMenu.fields.contentPages.length > 0;
    const hasSubMenus =
      subMenu.fields.subMenus && subMenu.fields.subMenus.length > 0;

    if (!hasContentPages && !hasSubMenus) {
      // If there are no content pages and no submenus, render a simple link
      return (
        <div
          key={subMenu.sys.id}
          className={`${isActive ? "active-submenu" : ""} ${
            level > 0 ? "nested-menu-item" : ""
          }`}
        >
          <Link
            to={
              subMenu.fields.title === "PreTicketSearch"
                ? "pre-ticket-search"
                : currentPath
            } // Adjust your alternate path here
            onClick={() => {
              setActivePath(currentPath);
              handleButtonClick();
            }}
            className="simple-link"
          >
            {subMenu.fields.title}
          </Link>
        </div>
      );
    }

    return (
      <Accordion.Item
        key={subMenu.sys.id}
        eventKey={currentPath}
        className={`${isActive ? "active-submenu" : ""} ${
          level > 0 ? "nested-accordion" : ""
        }`}
      >
        <Accordion.Header onClick={handleButtonClick}>
          <Link
            to={currentPath}
            onClick={() => {
              setActivePath(currentPath);
            }}
          >
            {subMenu.fields.title}
          </Link>
        </Accordion.Header>
        <Accordion.Body className={isOpen ? "show" : ""}>
          {hasContentPages && (
            <ul>
              {subMenu.fields.contentPages.map((page) => (
                <li
                  key={page?.sys?.id}
                  className={
                    activePath === `${currentPath}/${page?.fields?.slug}`
                      ? "active-content"
                      : ""
                  }
                >
                  <Link
                    to={`${currentPath}/${page?.fields?.slug}`}
                    onClick={() => {
                      setActivePath(`${currentPath}/${page?.fields?.slug}`);
                      handleButtonClick();
                    }}
                  >
                    {page.fields?.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {hasSubMenus && (
            <Accordion alwaysOpen>
              <div className="submenu-only">
                {subMenu.fields.subMenus.map((nestedSubMenu) =>
                  renderSubMenu(nestedSubMenu, currentPath, level + 1)
                )}
              </div>
            </Accordion>
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const renderTopLevelMenu = (mainMenu) => {
    console.log("mainMenu", mainMenu.fields.subMenus);
    return (
      <NavDropdown
      key={mainMenu.sys.id}
      title={
        mainMenu.sys.id === "789eUJ6wre83jVtjqACeEO" ? (
          <span>{mainMenu.fields.title}</span> // Render plain text if sys.id matches
        ) : (
          <Link to={`/${mainMenu.fields?.slug}`}>{mainMenu.fields.title}</Link> // Render Link otherwise
        )
      }
      
        id={`${mainMenu.fields.title.toLowerCase()}-dropdown`}
        show={dropdownOpen[mainMenu.sys.id]}
        onMouseEnter={
          mainMenu.fields.subMenus
            ? () => handleMouseEnter(mainMenu.sys.id)
            : undefined
        }
        onMouseLeave={
          mainMenu.fields.subMenus
            ? () => handleMouseLeave(mainMenu.sys.id)
            : undefined
        }
        className="multi-column-dropdown"
      >
        {mainMenu.fields.subMenus && (
          <div
            className="dropdown-menu-content"
            onMouseEnter={() => handleMouseEnter(mainMenu.sys.id)}
            onMouseLeave={() => handleMouseLeave(mainMenu.sys.id)}
          >
            {/* Check if subMenus exists and has items */}
            {mainMenu.fields.subMenus && mainMenu.fields.subMenus.length > 0
              ? mainMenu.fields.subMenus.map((subMenu) => {
                  let thumbnailUrl = null;

                  // Check if this subMenu has a thumbnail
                  const thumbnail = subMenu.fields.thumbnail;
                  if (thumbnail && thumbnail.length > 0) {
                    thumbnailUrl = thumbnail[0].fields.file.url;
                    thumbnailUrl = thumbnailUrl.startsWith("//")
                      ? "https:" + thumbnailUrl
                      : thumbnailUrl;
                  }

                  return (
                    <div key={subMenu.sys.id} className="dropdown-column">
                      <NavDropdown.Header
                        className="dropdown-header"
                        as={Link}
                        to={
                          subMenu.fields.redirect
                            ? subMenu.fields.redirect
                            : `/${mainMenu.fields.slug}/${subMenu.fields.slug}`
                        }
                        onClick={() => handleItemClick(mainMenu.sys.id)}
                        target={
                          subMenu.fields.redirect?.startsWith("http")
                            ? "_blank"
                            : undefined
                        } // Open in new tab for external links
                        rel={
                          subMenu.fields.redirect?.startsWith("http")
                            ? "noopener noreferrer"
                            : undefined
                        } // Security for new tab
                      >
                        <div>{subMenu.fields.title}</div>
                        {thumbnailUrl && (
                          <div style={{ marginTop: "10px" }}>
                            {" "}
                            {/* Add spacing between title and thumbnail */}
                            <img
                              src={thumbnailUrl}
                              alt={`${subMenu.fields.title} Thumbnail`}
                              className="submenu-thumbnail"
                              style={{ width: "180px" }} // Adjust size as needed
                            />
                          </div>
                        )}
                      </NavDropdown.Header>

                      {/* Check if contentPages exists and map */}
                      {subMenu.fields.contentPages &&
                        subMenu.fields.contentPages.length > 0 &&
                        subMenu.fields.contentPages.map((contentPage) => (
                          <NavDropdown.Item
                            key={contentPage.sys.id}
                            as={Link}
                            to={`/${mainMenu?.fields?.slug}/${subMenu?.fields?.slug}/${contentPage?.fields?.slug}`}
                            onClick={() => handleItemClick(mainMenu.sys.id)}
                          >
                            • {contentPage.fields.title}
                          </NavDropdown.Item>
                        ))}

                      {/* Check if nested subMenus exist */}
                      {subMenu.fields.subMenus &&
                        subMenu.fields.subMenus.length > 0 && (
                          <NavDropdown.Item as="div" className="nested-submenu">
                            {subMenu.fields.subMenus.map((nestedSubMenu) => (
                              <NavDropdown.Item
                                key={nestedSubMenu.sys.id}
                                as={Link}
                                to={`/${mainMenu?.fields?.slug}/${subMenu?.fields?.slug}/${nestedSubMenu?.fields?.slug}`}
                                onClick={() => handleItemClick(mainMenu.sys.id)}
                                className="nested-submenu-item"
                              >
                                • {nestedSubMenu.fields.title}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown.Item>
                        )}
                    </div>
                  );
                })
              : []}
          </div>
        )}
      </NavDropdown>
    );
  };
  const relevantMainMenu = groupedContent?.find((menu) =>
    activePath.startsWith(`/${menu.fields?.slug}`)
  );

  return (
    <>
      <div className="gridentWrapper">
        <Navbar bg="light" className="top-navbar" expand="lg">
          <Container className="custom-container">
            <Row className="justify-content-between align-items-center">
              <Col md="auto">
                <div className="Logo">
                  <Link to="/">
                    Neiman Marcus <span>Group</span>
                  </Link>
                </div>
              </Col>
              <Col md="auto">
                <div className="tagLine">OPERATIONS PORTAL</div>
              </Col>
              <Col md="auto">
                <div className="header-right">
                  <div className="contactInfo">
                    <Nav.Link as={Link} to="/contacts">
                      CONTACTS
                    </Nav.Link>
                    <Nav.Link as={Link} to="https://visitor.r20.constantcontact.com/manage/optin?v=001tFsIczu8YQAegew013zpkwNnNa5OBPiL">
                      SIGN UP FOR EMAIL NOTIFICATIONS
                    </Nav.Link>
                  </div>
           
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>

        <Navbar bg="light" expand="lg" className="menu-navbar">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
                {groupedContent &&
                  Array?.isArray(groupedContent) &&
                  groupedContent?.map(renderTopLevelMenu)}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {relevantMainMenu && relevantMainMenu.fields.subMenus && (
        <div className="contentfulsidebar" ref={divRef}>
          <h3>
            <Link to={`/${relevantMainMenu?.fields?.slug}`}>
              {relevantMainMenu?.fields?.title}
            </Link>
          </h3>
          <Accordion defaultActiveKey={openKeys} alwaysOpen>
            {relevantMainMenu?.fields?.subMenus?.map((subMenu) =>
              renderSubMenu(subMenu, `/${relevantMainMenu?.fields?.slug}`)
            )}
          </Accordion>
        </div>
      )}
    </>
  );
};

export default NavigationBar;
