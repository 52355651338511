import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPONumber } from "../../services/poGet";
import "./../../App.css";
function PreTicketSearch() {
  const [poNumbers, setPoNumbers] = useState("");
  const [poError, setPoError] = useState("");
  const [poSearchResults, setPoSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invalidPoDetails, setInvalidPoDetails] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.validPoDetails) {
      setPoSearchResults(location.state.validPoDetails);

      const poNumbersFromState = location.state.validPoDetails
        .map((po) => po.po_number)
        .join("\n");
      setPoNumbers(poNumbersFromState);
    } else {
      const searchParams = new URLSearchParams(location.search);
      const poNumbersFromUrl = searchParams.get("poNumbers");
      if (poNumbersFromUrl) {
        setPoNumbers(poNumbersFromUrl.split(",").join("\n"));
      }
    }
  }, [location]);
  const handleVerificationSubmit = async (event) => {
    event.preventDefault();

    if (!poNumbers.trim()) {
      setPoError("This field is required.");
      return;
    }

    const uniquePoNumbers = Array.from(
      new Set(
        poNumbers
          .split("\n")
          .map((po) => po.trim())
          .filter(Boolean)
      )
    ).join(",");

    if (!uniquePoNumbers) {
      setPoError("Please enter at least one valid PO number.");
      return;
    }

    if (uniquePoNumbers.split(",").length > 10) {
      setPoError("You can enter up to 10 unique PO numbers.");
      return;
    }

    setLoading(true);
    setPoError("");
    setPoSearchResults(null);
    setInvalidPoDetails([]);

    try {
      const response = await checkPONumber(uniquePoNumbers);
      console.log("API Response:", response.data);

      const fetchedPoDetails = response.data.map((poData) => {
        if (poData && poData.po_number) {
          return {
            po_number: poData.po_number,
            cancel_date: poData.cancel_date,
            fedex_number: poData.fedex_number,
          };
        }
        return {
          po_number: poData?.po_number || "Unknown",
          error: "Invalid PO number",
        };
      });

      const validResults = fetchedPoDetails.filter((po) => !po.error);
      const invalidResults = fetchedPoDetails.filter((po) => po.error);

      console.log("Valid POs:", validResults);
      console.log("Invalid POs:", invalidResults);

      if (validResults.length === 0) {
        setPoError("No valid PO numbers found.");
      }

      setPoSearchResults(validResults);
      setInvalidPoDetails(invalidResults);
    } catch (error) {
      console.error("Error during PO search:", error);
      setPoError("Error fetching PO details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setPoNumbers("");
    setPoError("");
    setPoSearchResults(null);
    setInvalidPoDetails([]);
  };

  return (
    <div className="banner4 mx-10 my-5">
      <h3>PreTicket Search</h3>
      <p className="header-description">
        Please use this tool to obtain tracking for Neiman Marcus Stores and
        Bergdorf Goodman preticket packets. A total of 10 purchase orders can be
        tracked at one time. Please enter one PO per line by typing in the PO
        and hitting the enter key. Then click the Search button to check the
        status of those POs. Purchase orders must be in "approved" status before
        tickets can be shipped. Purchase Orders will not have tracking
        information until 48 hours after they are approved.
      </p>
      <form onSubmit={handleVerificationSubmit}>
        {poError && (
          <span className="error-message" style={{ color: "red" }}>
            {poError}
          </span>
        )}
        <div
          className="form-field"
          style={{ position: "relative"}}
        >
          <label
            htmlFor="po-number"
            style={{
              display: "inline-block",
              marginBottom: "5px",
              width: "150px",
              fontSize: "16px",
            }}
          >
            PO Numbers
          </label>
          <div
            style={{
              width: "100%",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              maxWidth: "350px",
            }}
          >
            <textarea
              id="po-number"
              rows="5"
              cols={40}
              placeholder="Enter PO numbers (one per line)"
              value={poNumbers}
              onChange={(e) => {
                setPoNumbers(e.target.value);
                if (e.target.value.trim()) setPoError("");
              }}
              className={poError ? "error" : ""}
              style={{
                width: "100%",
                resize: "both",
                paddingTop: "10px",
                fontSize: "14px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "16px",
            marginTop: "10px",
            justifyContent: "start",
          }}
        >
          <button
            type="submit" className="submit-button">
            {/* {loading ? "Searching..." : "Submit"} */}Submit
          </button>
          <button
            type="button"
            onClick={handleClear} className="clear-button">                      
            Clear
          </button>
        </div>
      </form>

      {invalidPoDetails.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <h3 style={{ color: "red" }}>Invalid Purchase Orders</h3>
          {invalidPoDetails.map((po, index) => (
            <p key={index} style={{ color: "red" }}>
              {po.po_number}: {po.error}
            </p>
          ))}
        </div>
      )}

      {poSearchResults && poSearchResults.length > 0 && (
        <div style={{ marginTop: "50px" }}>
           <h3 style={{ marginTop: "50px" }}>PO Search Results</h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    justifyContent: "center",
                  }}
                >
                  PO Number
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    justifyContent: "center",
                  }}
                >
                  Cancel Date
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    justifyContent: "center",
                  }}
                >
                  FedEx Number
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    backgroundColor: "",
                    justifyContent: "center",
                  }}
                >
                  Freight
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    backgroundColor: '',
                    justifyContent: "center",
                  }}
                >
                 ticket cost
                </th>
              </tr>
            </thead>
            <tbody>
              {poSearchResults.map((poDetails) => (
                <tr key={poDetails.po_number}>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {poDetails.po_number}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {poDetails.cancel_date ? new Date(poDetails.cancel_date).toISOString().split("T")[0] : "N/A"}
                  </td>

                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {poDetails.fedex_number}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {poDetails.freight}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {poDetails.ticket_cost}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
export default PreTicketSearch;
