// src/utils/printAndPdfUtils.js
import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { getPath } from "../../App";

import generatePdf from "../../services/documentLibrary";

// Utility function for getting alignment style
const getAlignmentStyle = (alignment) => ({
  display: "block",
  marginLeft:
    alignment === "left" ? "0" : alignment === "right" ? "auto" : "auto",
  marginRight:
    alignment === "right" ? "0" : alignment === "left" ? "auto" : "auto",
});

// Utility function for processing colored text with formatting
export const addColourAndFormatting = (children = []) => {
  let combinedText = "";
  const parts = [];

  const processTextWithColor = (text) => {
    const regex = /\*\((.*?)\)\*\[(#\w+)\]/g;
    let match;
    let lastIndex = 0;
    const textParts = [];

    while ((match = regex.exec(text)) !== null) {
      const [fullMatch, matchedText, color] = match;
      const matchStart = match.index;
      const matchEnd = matchStart + fullMatch.length;

      if (lastIndex < matchStart) {
        textParts.push(text.slice(lastIndex, matchStart));
      }

      textParts.push(
        <span key={`${match.index}-${color}`} style={{ color }}>
          {matchedText}
        </span>
      );

      lastIndex = matchEnd;
    }

    if (lastIndex < text.length) {
      textParts.push(text.slice(lastIndex));
    }

    return textParts;
  };

  const applyMarks = (text, marks) => {
    let formattedText = text;
    marks.forEach((mark) => {
      switch (mark.type) {
        case "bold":
          formattedText = <strong key={`bold-${text}`}>{formattedText}</strong>;
          break;
        case "italic":
          formattedText = <em key={`italic-${text}`}>{formattedText}</em>;
          break;
        case "underline":
          formattedText = <u key={`underline-${text}`}>{formattedText}</u>;
          break;
        default:
          break;
      }
    });
    return formattedText;
  };

  children.forEach((child) => {
    if (typeof child === "string") {
      combinedText += child;
    } else if (typeof child === "object" && child.nodeType === "text") {
      if (combinedText) {
        parts.push(...processTextWithColor(combinedText));
        combinedText = "";
      }

      const coloredTextParts = processTextWithColor(child.value);
      const formattedParts = coloredTextParts.map((part) => {
        if (typeof part === "string") {
          return applyMarks(part, child.marks);
        }
        return part;
      });

      parts.push(...formattedParts);
    } else if (
      typeof child === "object" &&
      child.props &&
      child.props.children
    ) {
      if (combinedText) {
        parts.push(...processTextWithColor(combinedText));
        combinedText = "";
      }

      const nestedContent = child.props.children;
      const formattedContent = Array.isArray(nestedContent)
        ? addColourAndFormatting(nestedContent)
        : nestedContent;

      parts.push(
        React.cloneElement(child, { ...child.props }, formattedContent)
      );
    } else {
      if (combinedText) {
        parts.push(...processTextWithColor(combinedText));
        combinedText = "";
      }

      parts.push(child);
    }
  });

  if (combinedText) {
    parts.push(...processTextWithColor(combinedText));
  }

  return parts;
};

// Function to handle printing of tab content
export const handlePrint = (contentRef) => {
  const printWindow = document.createElement("iframe");
  printWindow.style.position = "absolute";
  printWindow.style.width = "0";
  printWindow.style.height = "0";
  printWindow.style.border = "none";
  document.body.appendChild(printWindow);

  const printDoc =
    printWindow.contentDocument || printWindow.contentWindow.document;
  const tabContent = contentRef.current.innerHTML;

  printDoc.open();
  printDoc.write(`
    <html>
      <head>
        <title>Print Tab Content</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 20px; }
          img { max-width: 100%; }
        </style>
      </head>
      <body>
        ${tabContent}
      </body>
    </html>
  `);
  printDoc.close();
  printWindow.contentWindow.focus();
  printWindow.contentWindow.print();

  setTimeout(() => {
    document.body.removeChild(printWindow);
  }, 1000);
};

// Function to handle saving content as PDF
export const handleSaveAsPDF = async (
  contentRef,
  contentItemTitle,
  setLoading
) => {
  try {
    if (!contentRef.current) return;

    const input = contentRef.current;
    console.log("Generated HTML:", input.outerHTML);

    // Helper function to fix links and images
    const fixLinksAndImages = (html) => {
      const baseUrl = window.location.origin;
      return html
        .replace(/href="(\/[^"]+)"/g, (_, p1) => `href="${baseUrl}${p1}"`)
        .replace(/src="(\/[^"]+)"/g, (_, p1) => `src="https://${p1.slice(1)}"`)
        .replace(
          /src="http:\/\/\/(images\.ctfassets\.net[^"]+)"/g,
          (_, p1) => `src="https://${p1}"`
        )
        .replace(/<[^>]+>(undefined)+<\/[^>]+>/g, "") // Remove elements containing 'undefined'
        .replace(/<ul>\s*<\/ul>/g, "") // Remove empty lists
        .replace(/<li>\s*<\/li>/g, "");
    };

    const cleanedHTML = fixLinksAndImages(input.outerHTML);
    const pdfData = await generatePdf(cleanedHTML);
    console.log("Generated PDF:", pdfData);

    // Function to download the generated PDF
    const downloadPdf = (base64Data, fileName) => {
      const binaryString = window.atob(base64Data);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const sanitizedFileName = contentItemTitle
      .replace(/[^a-z0-9]/gi, "_")
      .toLowerCase();
    downloadPdf(pdfData, sanitizedFileName);
  } catch (error) {
    console.error("PDF generation error:", error);
  } finally {
    // Hide loading state
  }
};



// Rendering options
export const getRenderOptions = (alignment, assets = [], entry = []) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{addColourAndFormatting([text])}</strong>,
    [MARKS.ITALIC]: (text) => <em>{addColourAndFormatting([text])}</em>,
    [MARKS.UNDERLINE]: (text) => <u>{addColourAndFormatting([text])}</u>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1>{addColourAndFormatting(children)}</h1>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p>{addColourAndFormatting(children)}</p>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file, description } = node.data.target.fields;
      const imageUrl = file.url;
      const altText = description || "Image";

      return (
        <div
          className="image-container"
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: alignment === "center" ? "center" : "flex-start",
          }}
        >
          <img
            src={imageUrl}
            alt={altText}
            style={{ maxWidth: "100%", ...getAlignmentStyle(alignment) }}
          />
        </div>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const urlfrom = node.data.target.fields.file.url;
      console.log("urlfrom", urlfrom);

      return (
        <a
          href={`https:${urlfrom}`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  },
});

// Additional options function for rendering
export const options = (alignment, assets = []) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file, description } = node.data.target.fields;
      const imageUrl = file.url;
      const altText = description || "Image";

      return (
        <div
          className="image-container"
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: alignment === "center" ? "center" : "flex-start",
          }}
        >
          <img
            src={imageUrl}
            alt={altText}
            style={{ maxWidth: "100%", ...getAlignmentStyle(alignment) }}
          />
        </div>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const urlfrom = node.data.target.fields.file.url;
      console.log("urlfrom", urlfrom);

      return (
        <a
          href={`https:${urlfrom}`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  },
});
