import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import useContentful from "../../hooks/useContentful";
import useGroupedContent from "../../hooks/useGroupedContent";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getRenderOptions } from "../HelperFunctions/HelperFunctions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import "./DocumentLibrary.css";
import Loder from "../../assets/loder.gif";
import generatePdf from "../../services/documentLibrary";


const DocumentLibrary = () => {
  const {
    items: documentLibrary,
    loading: bannerLoading,
    error: bannerError,
  } = useContentful("documentLibrary");

  const groupedContent =
    useGroupedContent("nmopsPortal")[0]?.fields?.mainMenu || [];

  const [checkedItems, setCheckedItems] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.info("CheckedItems", checkedItems);
  }, [checkedItems]);

  const handleCheckboxChange = (key, isChecked) => {
    setCheckedItems((prev) => ({
      ...prev,
      [key]: isChecked,
    }));
  };

  const handleSubMenuCheckboxChange = (subMenuKey, subMenu, isChecked) => {
    // Update the state of the submenu
    handleCheckboxChange(subMenuKey, isChecked);

    // Function to toggle all child submenus and content pages based on the parent submenu's state
    const toggleAllChildren = (subMenu, subMenuKey, isChecked) => {
      // Toggle all nested submenus
      subMenu.fields.subMenus?.forEach((nestedSubMenu, nestedIndex) => {
        const nestedSubMenuKey = `${subMenuKey}-subSubMenu-${nestedIndex}`;
        handleCheckboxChange(nestedSubMenuKey, isChecked);

        // Recursively toggle all nested children
        toggleAllChildren(nestedSubMenu, nestedSubMenuKey, isChecked);
      });

      // Toggle all content pages under this submenu
      subMenu.fields.contentPages?.forEach((contentPage, contentIndex) => {
        const contentPageKey = `${subMenuKey}-contentPage-${contentIndex}`;
        handleCheckboxChange(contentPageKey, isChecked);
      });
    };

    // Toggle all children of the submenu (select all or deselect all)
    toggleAllChildren(subMenu, subMenuKey, isChecked);
  };

  const handleContentPageCheckboxChange = (contentPageKey) => {
    setCheckedItems((prev) => ({
      ...prev,
      [contentPageKey]: !prev[contentPageKey],
    }));
  };


    const generateHTML = () => {
      const baseUrl = window.location.origin;
      const tocEntries = []; // To store ToC entries
  
      const content = groupedContent
    .filter(
      (mainMenu) =>
        mainMenu.fields &&
        mainMenu.fields.subMenus &&
        mainMenu.fields.subMenus.length > 0 &&
        mainMenu.fields.title !== "Vendor Links"
    )
    .map((mainMenu, mainIndex) => {
      if (!mainMenu.fields || !mainMenu.fields.title) {
        console.warn(`Main Menu title is undefined for index: ${mainIndex}`);
        return ""; // Skip if undefined
      }
  
      const subMenuItems = mainMenu.fields.subMenus
        .map((subMenu, subIndex) => {
          const subMenuKey = `subMenu-${mainIndex}-${subIndex}`;
          let subMenuContent = "";
  
            // Check if subMenu is checked or if any contentPages under subSubMenus are checked
          const isSubMenuSelected =
            checkedItems[subMenuKey] ||
            subMenu.fields.subMenus?.some((subSubMenu) =>
              subSubMenu.fields.contentPages?.some(
                (contentPage, contentIndex) =>
                    checkedItems[
                      `${subMenuKey}-contentPage-${contentIndex}`
                    ]
              )
            );
  
            if (
              isSubMenuSelected ||
              subMenu.fields.contentPages?.some(
                (contentPage, contentIndex) =>
                  checkedItems[
                    `${subMenuKey}-contentPage-${contentIndex}`
                  ]
              )
            ) {
            if (!subMenu || !subMenu.fields || !subMenu.fields.title) {
                console.warn(
                  `Sub Menu title is undefined for index: ${subIndex}`
                );
              return ""; // Skip if undefined
            }
  
            tocEntries.push({
              title: subMenu.fields.title,
              contentPages: [],
            });
  
            subMenuContent += `
              <li>
                <strong>${subMenu.fields.title}</strong>
                ${
                  subMenu.fields.description
                    ? ReactDOMServer.renderToStaticMarkup(
                        documentToReactComponents(
                          subMenu.fields.description,
                          getRenderOptions()
                        )
                      )
                    : ""
                }
              </li>`;
            }
  
            // Render contentPages directly under the subMenu (this part was missing)
            const subMenuContentPages = subMenu.fields.contentPages
              ?.map((contentPage, contentIndex) => {
                const contentPageKey = `${subMenuKey}-contentPage-${contentIndex}`;
                if (checkedItems[contentPageKey]) {
                  if (
                    !contentPage ||
                    !contentPage.fields ||
                    !contentPage.fields.title
                  ) {
                    console.warn(
                      `Content Page title is undefined for index: ${contentIndex}`
                    );
                    return ""; // Skip if undefined
                  }
  
                  // Add content page to ToC
                  tocEntries[tocEntries.length - 1].contentPages.push(
                    contentPage.fields.title
                  );
  
                  const tabs = contentPage.fields.tabs
                    ?.map((tab) => {
                      return `
                      <div>
                        <h5>${tab.fields.tabTitle}</h5>
                        ${
                          tab.fields.tabContent
                            ? ReactDOMServer.renderToStaticMarkup(
                                documentToReactComponents(
                                  tab.fields.tabContent,
                                  getRenderOptions()
                                )
                              )
                            : ""
                        }
                      </div>`;
                    })
                    .join("");
  
                  return `
                  <li>
                    <h4>${contentPage.fields.title}</h4>
                    ${
                      contentPage.fields.description
                        ? ReactDOMServer.renderToStaticMarkup(
                            documentToReactComponents(
                              contentPage.fields.description,
                              getRenderOptions()
                            )
                          )
                        : ""
                    }
                    <div>${tabs}</div>
                  </li>`;
                }
                return ""; // Skip if not checked
              })
              .filter(item => item) // Filter out empty items
              .join("");
  
            // Render subSubMenus and their contentPages
            const subSubMenuItems = subMenu.fields.subMenus
              ?.map((subSubMenu, subSubIndex) => {
                const subSubMenuKey = `${subMenuKey}-subSubMenu-${subSubIndex}`;
  
                const isSubSubMenuSelected =
                  checkedItems[subSubMenuKey] ||
                  subSubMenu.fields.contentPages?.some(
                    (contentPage, contentIndex) =>
                      checkedItems[
                        `${subSubMenuKey}-contentPage-${contentIndex}`
                      ]
                  );
  
                if (isSubSubMenuSelected) {
                  if (
                    !subSubMenu ||
                    !subSubMenu.fields ||
                    !subSubMenu.fields.title
                  ) {
                    console.warn(
                      `SubSub Menu title is undefined for index: ${subSubIndex}`
                    );
                    return ""; // Skip if undefined
                  }
  
                  tocEntries.push({
                    title: subSubMenu.fields.title,
                    contentPages: [],
                  });
  
                  const subSubMenuContentPages = subSubMenu.fields.contentPages
                    ?.map((contentPage, contentIndex) => {
                      const contentPageKey = `${subSubMenuKey}-contentPage-${contentIndex}`;
  
                      if (checkedItems[contentPageKey]) {
                        if (
                          !contentPage ||
                          !contentPage.fields ||
                          !contentPage.fields.title
                        ) {
                          console.warn(
                            `Content Page title is undefined for index: ${contentIndex}`
                          );
                          return ""; // Skip if undefined
                        }
  
                        tocEntries[tocEntries.length - 1].contentPages.push(
                          contentPage.fields.title
                        );
  
                        const tabs = contentPage.fields.tabs
                          ?.map((tab) => {
                            return `
                            <div>
                              <h5>${tab.fields.tabTitle}</h5>
                              ${
                                tab.fields.tabContent
                                  ? ReactDOMServer.renderToStaticMarkup(
                                      documentToReactComponents(
                                        tab.fields.tabContent,
                                        getRenderOptions()
                                      )
                                    )
                                  : ""
                              }
                            </div>`;
                          })
                          .join("");
  
                        return `
                        <li>
                          <h4>${contentPage.fields.title}</h4>
                          ${
                            contentPage.fields.description
                              ? ReactDOMServer.renderToStaticMarkup(
                                  documentToReactComponents(
                                    contentPage.fields.description,
                                    getRenderOptions()
                                  )
                                )
                              : ""
                          }
                          <div>${tabs}</div>
                        </li>`;
                      }
                      return "";
                    })
                    .filter(item => item) // Filter out empty items
                    .join("");
  
                  return `
                  <li>
                    <strong>${subSubMenu.fields.title}</strong>
                    ${
                      subSubMenu.fields.description
                        ? ReactDOMServer.renderToStaticMarkup(
                            documentToReactComponents(
                              subSubMenu.fields.description,
                              getRenderOptions()
                            )
                          )
                        : ""
                    }
                    <ul>${subSubMenuContentPages}</ul>
                  </li>`;
                }
                return "";
              })
              .filter(item => item) // Filter out empty items
              .join("");
  
            // Return the combined content for subMenu, its direct contentPages, and subSubMenus
            return `
            <li>
              ${subMenuContent}
              <ul>${subMenuContentPages}${subSubMenuItems}</ul>
            </li>`;
        })
        .filter(item => item) // Filter out empty items
        .join("");
  
      return `
      <div>
        <ul>${subMenuItems}</ul>
      </div>`;
    })
    .filter(item => item) // Filter out empty items
    .join("");

    // Generate Table of Contents
    const generateTOC = () => {
      return tocEntries
        .map((entry) => {
          const contentLinks = entry.contentPages
            .map((page) => `<li>${page}</li>`)
            .join("");
          return `<li>${entry.title}<ul>${contentLinks}</ul></li>`;
        })
        .join("");
    };

    const fixLinksAndImages = (html) => {
      const baseUrl = window.location.origin;
    
      // Format links
      let fixedHtml = html.replace(/href="(\/[^"]+)"/g, (match, p1) => `href="${baseUrl}${p1}"`);
      
      // Format images
      fixedHtml = fixedHtml.replace(
        /src="(\/[^"]+)"/g,
        (match, p1) => `src="https://${p1.slice(1)}"`
      );
    
      // Contentful images
      fixedHtml = fixedHtml.replace(
        /src="http:\/\/\/(images\.ctfassets\.net[^"]+)"/g,
        (match, p1) => `src="https://${p1}"`
      );
    
      // Remove elements that only contain 'undefined'
      fixedHtml = fixedHtml.replace(/<[^>]+>(undefined)+<\/[^>]+>/g, '');
    
      // Remove empty <ul> and <li> tags that may result from removing undefined items
      fixedHtml = fixedHtml.replace(/<ul>\s*<\/ul>/g, '').replace(/<li>\s*<\/li>/g, '');
    
      return fixedHtml;
    };
    

    const htmlContent = `
<!DOCTYPE html>
<html>
<head>
    <title>Document Library</title>
<style>
    body {
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
    }

    .container {
        margin-top: 120px; /* Space for the fixed header */
        margin-bottom: 80px; /* Space for the footer */
        padding: 20px;
    }
    h3, h2, h5 {
        color: #ad3477;
        font-weight: bold;
    }
    
    /* Table Styling */
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
    }
    th, td {
        padding: 10px;
        text-align: left;
        border: 1px solid #ddd;
    }
    th {
        background-color: #f8f8f8;
        color: #ad3477; /* Table header color */
        font-weight: bold;
    }
    tr:nth-child(even) {
        background-color: #f9f9f9; /* Alternating row background */
    }
    tr:hover {
        background-color: #f1f1f1; /* Row hover effect */
    }

    /* Responsive table */
    @media (max-width: 768px) {
        table, th, td {
            display: block;
            width: 100%;
        }
        th {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        td {
            position: relative;
            padding-left: 50%;
        }
        td:before {
            content: attr(data-label);
            position: absolute;
            left: 10px;
            font-weight: bold;
            color: #ad3477;
        }
    }
</style>

</head>
<body>
    <div id="table-of-contents" class="container">
        <h2>Table of Contents</h2>
        <ul>${generateTOC()}</ul>
    </div>
    <div class="container">
        ${fixLinksAndImages(content)}
    </div>
</body>
</html>
`;
    const payload = htmlContent;
    console.log(JSON.stringify({ payload }));

    const handlePdfGeneration = async (payload) => {
      try {
        setLoading(true);
        const pdfBase64 = await generatePdf(payload);
        downloadPdf(pdfBase64, "generated-document.pdf");
      } catch (error) {
        console.error("PDF generation failed:", error);
      } finally {
        setLoading(false);
      }
    };

    const downloadPdf = (base64Data, fileName) => {
      const binaryString = window.atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    setLoading(true);
    handlePdfGeneration(payload);
  };

  if (bannerLoading) {
    return (
      <div className="content-page">
        <h1>
          <Skeleton width={300} />
        </h1>
        <Skeleton count={5} />
      </div>
    );
  }

  if (bannerError) {
    return <div>Error loading document library: {bannerError.message}</div>;
  }

  if (!documentLibrary || documentLibrary.length === 0) {
    return <p>No documents available</p>;
  }

  return (
    <div className="content-page">
      <div>
        <h1>NMGOPS Document Library </h1>
        <p>
          Select one or many documents from the lists below and click 'Generate
          PDF' to generate a Adobe Acrobat PDF document with a table of
          contents. Many selections will take longer to process, so please be
          patient.
        </p>
      </div>
      <div className="grouped-content">
        {groupedContent
          .filter(
            (mainMenu) =>
              mainMenu.fields &&
              mainMenu.fields.subMenus &&
              mainMenu.fields.subMenus.length > 0 &&
              mainMenu.fields.title !== "Vendor Links"
          )
          .map((mainMenu, mainIndex) => (
            <div key={mainIndex}>
              <h3>{mainMenu.fields.title}</h3>

              <ul>
                {mainMenu.fields.subMenus.map((subMenu, subIndex) => {
                  const subMenuKey = `subMenu-${mainIndex}-${subIndex}`;

                  return (
                    <li key={subIndex}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={() =>
                            handleSubMenuCheckboxChange(
                              subMenuKey,
                              subMenu,
                              !checkedItems[subMenuKey]
                            )
                          }
                          checked={checkedItems[subMenuKey] || false}
                        />
                        <strong>{subMenu.fields.title}</strong>
                      </label>

                      {/* Render Nested Sub-SubMenus */}
                      {subMenu.fields.subMenus &&
                        subMenu.fields.subMenus.length > 0 && (
                          <ul>
                            {subMenu.fields.subMenus.map(
                              (subSubMenu, subSubIndex) => {
                                const subSubMenuKey = `${subMenuKey}-subSubMenu-${subSubIndex}`;

                                return (
                                  <li key={subSubIndex}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          handleSubMenuCheckboxChange(
                                            subSubMenuKey,
                                            subSubMenu,
                                            !checkedItems[subSubMenuKey]
                                          )
                                        }
                                        checked={
                                          checkedItems[subSubMenuKey] || false
                                        }
                                      />
                                      <strong>{subSubMenu.fields.title}</strong>
                                    </label>

                                    {/* Content Pages under Sub-SubMenu */}
                                    {subSubMenu.fields.contentPages &&
                                      subSubMenu.fields.contentPages.length >
                                        0 && (
                                        <ul>
                                          {subSubMenu.fields.contentPages.map(
                                            (contentPage, contentIndex) => {
                                              const contentPageKey = `${subSubMenuKey}-contentPage-${contentIndex}`;

                                              return (
                                                <li key={contentIndex}>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      onChange={() =>
                                                        handleContentPageCheckboxChange(
                                                          contentPageKey
                                                        )
                                                      }
                                                      checked={
                                                        checkedItems[
                                                          contentPageKey
                                                        ] || false
                                                      }
                                                    />
                                                    {contentPage.fields.title}
                                                  </label>

                                                  {/* Render content page description and tabs */}
                                                  {checkedItems[
                                                    contentPageKey
                                                  ] &&
                                                    contentPage.fields
                                                      .description && (
                                                      <div className="content-description">
                                                        {documentToReactComponents(
                                                          contentPage.fields
                                                            .description,
                                                          getRenderOptions()
                                                        )}
                                                        {/* Render tabs */}
                                                        {contentPage.fields
                                                          .tabs &&
                                                          contentPage.fields
                                                            .tabs.length >
                                                            0 && (
                                                            <div className="content-tabs">
                                                              {contentPage.fields.tabs.map(
                                                                (
                                                                  tab,
                                                                  tabIndex
                                                                ) => (
                                                                  <div
                                                                    key={
                                                                      tabIndex
                                                                    }
                                                                    className="tab-content"
                                                                  >
                                                                    <h5>
                                                                      {
                                                                        tab
                                                                          .fields
                                                                          .title
                                                                      }
                                                                    </h5>
                                                                    {tab.fields
                                                                      .tabContent &&
                                                                      documentToReactComponents(
                                                                        tab
                                                                          .fields
                                                                          .tabContent,
                                                                        getRenderOptions()
                                                                      )}
                                                                  </div>
                                                                )
                                                              )}
                                                            </div>
                                                          )}
                                                      </div>
                                                    )}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        )}

                      {/* Content Pages under SubMenu */}
                      {subMenu.fields.contentPages &&
                        subMenu.fields.contentPages.length > 0 && (
                          <ul>
                            {subMenu.fields.contentPages.map(
                              (contentPage, contentIndex) => {
                                const contentPageKey = `${subMenuKey}-contentPage-${contentIndex}`;

                                return (
                                  <li key={contentIndex}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          handleContentPageCheckboxChange(
                                            contentPageKey
                                          )
                                        }
                                        checked={
                                          checkedItems[contentPageKey] || false
                                        }
                                      />
                                      {contentPage.fields.title}
                                    </label>
                                    {checkedItems[contentPageKey] &&
                                      contentPage.fields.description && (
                                        <div className="content-description">
                                          {documentToReactComponents(
                                            contentPage.fields.description,
                                            getRenderOptions()
                                          )}
                                          {/* Render tabs if they exist */}
                                          {contentPage.fields.tabs &&
                                            contentPage.fields.tabs.length >
                                              0 && (
                                              <div className="content-tabs">
                                                {contentPage.fields.tabs.map(
                                                  (tab, tabIndex) => (
                                                    <div
                                                      key={tabIndex}
                                                      className="tab-content"
                                                    >
                                                      <h5>
                                                        {tab.fields.title}
                                                      </h5>
                                                      {tab.fields.tabContent &&
                                                        documentToReactComponents(
                                                          tab.fields.tabContent,
                                                          getRenderOptions()
                                                        )}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                        </div>
                                      )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
      </div>

      <button
        onClick={generateHTML}
        className="generate-button"
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Generate PDF
      </button>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <img src={Loder} />
          </div>
        </div>
      )}

      <div>
        <hr class="new4" style={{ border: "1px solid red" }}></hr>
      </div>
      <div id="for-pdf" style={{ height: "800", width: "600" }} />
      <div className="document-library-container">
        {documentLibrary.map((libraryItem, index) => {
          const { title, documents } = libraryItem.fields;

          // For titles with a large number of files, split into multiple columns
          const isLarge = documents && documents.length > 15;

          return (
            <div
              key={index}
              className={`library-item ${isLarge ? "large" : ""}`}
            >
              <h2>{title}</h2>
              {documents && documents.length > 0 ? (
                <div
                  className={`documents-list ${isLarge ? "multi-column" : ""}`}
                >
                  {documents.map((doc, docIndex) => (
                    <a
                      key={docIndex}
                      href={`https:${doc.fields.file.url}`}
                      download={doc.fields.file.fileName}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="document-link"
                    >
                      {doc.fields.title}
                    </a>
                  ))}
                </div>
              ) : (
                <p>No documents available under this title.</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentLibrary;
