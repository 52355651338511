import axiosInstance from "./AxiosInstance";

export const checkPONumber = async (poNumber) => {
  try {
    const response = await axiosInstance.get('/po', {
      params: { poNumber }, 
    });
    return response.data; 
  } catch (error) {
    console.error("Error checking PO number:", error.response?.data || error.message);
    throw new Error("PO number not found"); 
  }
};

export const checkRoutingBySupplier = async (supplierSiteId, department) => {
  try {
    // Using axiosInstance for consistency
    const response = await axiosInstance.get('/vendors', {
      params: {
        supplierSiteId: supplierSiteId,
        departmentNumber: department
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching supplier routing:", error.response?.data || error.message);
    throw new Error(`Error fetching supplier routing: ${error.message}`);
  }
};