import axiosInstance from "./AxiosInstance";
const StoreService = {
    fetchStore: async (page, pageSize, sortBy, order, storeNumber=null) => {
        try {
          const params = { page, pageSize, sortBy, order};
          if (storeNumber) params.store_number = storeNumber; 
      
          console.log("Fetching store with parameters:", params);
      
          const response = await axiosInstance.get("/store", { params });
      
          console.log("Full API Response Data:", response.data);
      
          return response.data;
        } catch (error) {
          console.error("Failed to fetch stores:", error);
          throw error;
        } 
      }
};

export default StoreService;
