// import React from "react";
// import "./Contacts.css";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import ScrollToTop from "react-scroll-to-top";
// import useContentful from "../../hooks/useContentful";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"; 
// const ContactsPage = () => {


//   const { items: contact, loading: Loading, error: Error } = useContentful("contacts");
// const contacts = contact[0]
//   console.log(contacts,"contacts")




//   if (Loading) {
//     return <p>Loading...</p>;
//   }

//   if (Error) {
//     return <p>Error fetching data: {Error.message}</p>;
//   }
//   return (
//     <div className="contacts-container">

//       <h2>DOMESTIC</h2>

//       <h3>PARTNER RELATIONS</h3>
//       <div className="contacts-section">
//         <h4>NEIMAN MARCUS STORES & BERGDORF GOODMAN</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Jeremy Hazlewood</td>
//               <td>Group Manager</td>
//               <td>
//                 972-401-6715
//                 <br />
//                 Diana_Lopez@NeimanMarcus.com
//               </td>
//             </tr>
//             <tr>
//               <td>Mihaela Meehan</td>
//               <td>Supervisor</td>
//               <td>972-401-6738</td>
//             </tr>
//             <tr>
//               <td>Diana Lopez</td>
//               <td>Claim Dispute Specialist - Vendors A - C, S - Z & #'s</td>
//               <td></td>
//             </tr>
//             <tr>
//               <td>Diana Lopez</td>
//               <td>Claim Dispute Specialist - Vendors D - R</td>
//               <td></td>
//             </tr>
//             <tr>
//               <td colSpan="2">EMAIL</td>
//               <td>Partner_Relations@neimanmarcus.com</td>
//             </tr>
//           </tbody>
//         </table>

//         <h4>NEIMAN MARCUS DIRECT</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Jeremy Hazlewood</td>
//               <td>Manager</td>
//               <td>972-401-6715</td>
//             </tr>
//             <tr>
//               <td>Mihaela Meehan</td>
//               <td>Supervisor</td>
//               <td>972-401-6738</td>
//             </tr>
//             <tr>
//               <td>Vargina Espinoza</td>
//               <td>Specialist - Las Colinas</td>
//               <td>Vargina_Espinoza@NeimanMarcus.com</td>
//             </tr>
//             <tr>
//               <td>Denise Cerda</td>
//               <td>Coordinator - Pinnacle Park</td>
//               <td>Denise_Cerda@NeimanMarcus.com</td>
//             </tr>
//             <tr>
//               <td colSpan="2">EMAIL</td>
//               <td>Partner_Relations@neimanmarcus.com</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>

//       <h3>EDI</h3>
//       <div className="contacts-section">
//         <h4>NMG EDI Team</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Jeremy Hazlewood</td>
//               <td>Group Manager</td>
//               <td>972-401-6715</td>
//             </tr>
//             <tr>
//               <td>Mihaela Meehan</td>
//               <td>Supervisor</td>
//               <td>972-401-8738</td>
//             </tr>
//             <tr>
//               <td>Kimeshia Loyd</td>
//               <td>EDI Technical Analyst</td>
//               <td>972-401-6715</td>
//             </tr>
//             <tr>
//               <td>Laura Vasquez</td>
//               <td>EDI Business Analyst, EDI Onboarding</td>
//               <td>NMGEDIOutreach@neimanmarcus.com</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>

//       <h3>Transportation</h3>
//       <div className="contacts-section">
//         <h4>NEIMAN MARCUS STORES & BERGDORF GOODMAN</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Amanda Martin</td>
//               <td>Senior Vice President Logistics</td>
//               <td>903-235-2377</td>
//             </tr>
//             <tr>
//               <td>Ty Taylor</td>
//               <td>Director of Logistics</td>
//               <td>972-401-6528</td>
//             </tr>
//             <tr>
//               <td>Craig Kids</td>
//               <td>Domestic Transportation Senior Manager</td>
//               <td>003-303-9708</td>
//             </tr>
//             <tr>
//               <td>Chris Danielson</td>
//               <td>Domestic Transportation Manager</td>
//               <td>214-284-9817</td>
//             </tr>
//             <tr>
//               <td>Jade Lancaster</td>
//               <td>Domestic Transportation Analyst</td>
//               <td>469-202-1245</td>
//             </tr>
//             <tr>
//               <td>Janet Maynard</td>
//               <td>Carrier Freight Payment/ DC/Consolidator Claims</td>
//               <td>469-262-1236</td>
//             </tr>
//             <tr>
//               <td>Alisa Jones</td>
//               <td>Dangerous Goods Administration/ T-Claims Chargebacks</td>
//               <td>469-262-1236</td>
//             </tr>
//             <tr>
//               <td>Tina Robertson</td>
//               <td>Vendor & Truck Load Routing/ Exception Routing</td>
//               <td>469-262-1255</td>
//             </tr>
//             <tr>
//               <td>Olga Saldana</td>
//               <td>Consolidator Freight Release/Routing</td>
//               <td>469-262-1240</td>
//             </tr>
//           </tbody>
//         </table>

//         <h4>NEIMAN MARCUS DIRECT</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Ty Taylor</td>
//               <td>Director of Logistics</td>
//               <td>972-401-6528</td>
//             </tr>
//             <tr>
//               <td>Chris Putnam</td>
//               <td>E-Commerce Logistics Manager</td>
//               <td>972-401-8851</td>
//             </tr>
//             <tr>
//               <td>Michael Rollins</td>
//               <td>E-Commerce Logistics Claims Supervisor</td>
//               <td>972-401-6801</td>
//             </tr>
//             <tr>
//               <td>Kim Lewis</td>
//               <td>E-Commerce Logistics Supervisor</td>
//               <td>972-401-6939</td>
//             </tr>
//             <tr>
//               <td>Roxanne Davey</td>
//               <td>E-Commerce Logistics Business Analyst</td>
//               <td>972-401-6763</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>

//       <div className="contacts-section">
//         <h3>NMD RETURN TO VENDOR</h3>
//         <h4>LAS COLINAS</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Mark LaPenotiere</td>
//               <td>Manager</td>
//               <td>972-401-6982</td>
//             </tr>
//             <tr>
//               <td>Rudy Chavez</td>
//               <td>Supervisor</td>
//               <td>972-401-6800</td>
//             </tr>
//             <tr>
//               <td>Caesar Suante</td>
//               <td>Lead</td>
//               <td>972-401-6483</td>
//             </tr>
//             <tr>
//               <td>Erin Carpenter</td>
//               <td>Claims Coordinator</td>
//               <td>972-401-6919</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <div className="contacts-section">
//         <h4>PINNACLE PARK</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Sollarion "Buck" Bagley</td>
//               <td>Manager</td>
//               <td>214-331-3221</td>
//             </tr>
//             <tr>
//               <td>Luis Ruiz</td>
//               <td>Claims Coordinator</td>
//               <td>214-331-3221</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <div className="contacts-section">
//         <h4>NORTH CAROLINA</h4>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Title</th>
//               <th>Contact Info</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Michael Rollins</td>
//               <td>Supervisor Claims</td>
//               <td>460-262-1260</td>
//             </tr>
//             <tr>
//               <td>Susan Herrin</td>
//               <td>Claims Coordinator</td>
//               <td>460-262-1266</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <div className="contacts-section">
//         <h2>INTERNATIONAL</h2>
//         <p>
//           If you have a question concerning how to export your merchandise to
//           the United States for Neiman Marcus Stores, Neiman Marcus Direct, or
//           Bergdorf Goodman, our foreign buying agents are available to work
//           directly with your company to make this happen.
//         </p>
//         <p>
//           These agents will assist you with all of your transportation,
//           invoicing, and US Government Compliance questions. Please access the
//           information provided if you need help with any questions concerning
//           international shipments.
//         </p>
//         <div className="Accordian-section">
//           <Tabs
//             defaultActiveKey="profile"
//             id="uncontrolled-tab-example"
//             className="mb-3"
//           >
//             <Tab eventKey="home" title="Foreign Buying Agent">
//               <div style={{ padding: "20px" }}>
//                 <h2>Foreign Buying Agent Contacts</h2>

//                 <h3>ITALY, GREECE & THE NETHERLANDS</h3>
//                 <strong>Alvear SRL – Milan</strong>
//                 <p>
//                   Largo Augusto 3<br />
//                   20122 Milano, Italy
//                   <br />
//                   Telephone: +39 02 12 41 24 5 74
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Federica Lanati –{" "}
//                   <a href="mailto:federica.lanati@alveargroup.com">
//                     federica.lanati@alveargroup.com
//                   </a>
//                   <br />
//                   Sabrina Marotta –{" "}
//                   <a href="mailto:sabrina.marotta@alveargroup.com">
//                     sabrina.marotta@alveargroup.com
//                   </a>
//                 </p>

//                 <h3>FRANCE, BELGIUM & UNITED KINGDOM</h3>
//                 <strong>Alvear SRL – Paris</strong>
//                 <p>
//                   17 rue d'Antin
//                   <br />
//                   75002 Paris
//                   <br />
//                   Telephone: +33 6 31 27 0614
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Christelle Louvet –{" "}
//                   <a href="mailto:Christelle.louvet@alveargroup.com">
//                     Christelle.louvet@alveargroup.com
//                   </a>
//                   <br />
//                   Charlotte Lavier –{" "}
//                   <a href="mailto:charlotte.duval@alveargroup.com">
//                     charlotte.duval@alveargroup.com
//                   </a>
//                   <br />
//                   Oufany Gomis –{" "}
//                   <a href="mailto:oufany.gomis@alveargroup.com">
//                     oufany.gomis@alveargroup.com
//                   </a>
//                 </p>

//                 <h3>
//                   GERMANY, RUSSIA, DENMARK, SWEDEN, NORWAY, BULGARIA, AUSTRIA,
//                   UKRAINE, CZECH REPUBLIC, POLAND, LUXEMBOURG, TURKEY, ISRAEL,
//                   ROMANIA, LEBANON, SWITZERLAND, HUNGARY, UNITED ARAB EMIRATES
//                 </h3>
//                 <strong>DvK BUYING AGENCY GmbH & Co KG</strong>
//                 <p>
//                   Briandring 12a
//                   <br />
//                   60598 Frankfurt am Main, Germany
//                   <br />
//                   Telephone: 011-49-69-697147-60
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Draga von Koeckritz, Owner –{" "}
//                   <a href="mailto:draga_vonKoeckritz@dvk-buyingagency.com">
//                     draga_vonKoeckritz@dvk-buyingagency.com
//                   </a>
//                 </p>

//                 <h3>HONG KONG & CHINA</h3>
//                 <strong>F. R. GABBOTT AND COMPANY, LTD.</strong>
//                 <p>
//                   Unit A 8/Floor, Summit Building, 30 Man Yue Street,
//                   <br />
//                   Hunghom, Kowloon, Hong Kong, China
//                   <br />
//                   Telephone: 011-852-2-376-2081
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   <a href="mailto:gabbott@gabbott.com.hk">
//                     gabbott@gabbott.com.hk
//                   </a>
//                   <br />
//                   Ming Lai –{" "}
//                   <a href="mailto:minglai@gabbott.com.hk">
//                     minglai@gabbott.com.hk
//                   </a>
//                   <br />
//                   Ninle Tseng –{" "}
//                   <a href="mailto:ninletseng@gabbott.com.hk">
//                     ninletseng@gabbott.com.hk
//                   </a>
//                 </p>

//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   10/F Kader Building, 22 Kai Cheung Road, Kowloon Bay, Hong
//                   Kong
//                   <br />
//                   Telephone: 852-2621-2888
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Joyce Han –{" "}
//                   <a href="mailto:joycehan@weconnor.com">
//                     joycehan@weconnor.com
//                   </a>
//                   <br />
//                   Faye Tsai –{" "}
//                   <a href="mailto:fayetsai@weconnor.com">
//                     fayetsai@weconnor.com
//                   </a>
//                 </p>

//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   Unit D 5/F Block CD, Tian Xiang Building,
//                   <br />
//                   Chegongmiao, Futian, Shezhen, China 518042
//                   <br />
//                   Telephone: 86-755-2363-8300
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Tommy Wong –{" "}
//                   <a href="mailto:tommywong@weconnor.com">
//                     tommywong@weconnor.com
//                   </a>
//                   <br />
//                   Eric Choi –{" "}
//                   <a href="mailto:ericchoi@weconnor.com">
//                     ericchoi@weconnor.com
//                   </a>
//                 </p>

//                 <h3>TAIWAN</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   8/F, No. 111, Sec. 1, Keelung Road, Xinyi District,
//                   <br />
//                   Taipei City 110057, Taiwan, R.O.C.
//                   <br />
//                   Telephone: 886-2-7723-1801
//                 </p>
//                 <p>
//                   Contact:
//                   <br />
//                   Tommy Wong -{" "}
//                   <a href="mailto:tommywong@weconnor.com">
//                     tommywong@weconnor.com
//                   </a>
//                 </p>

//                 <h3>TURKEY</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   Barbaros Mah. Mor Sümbül Sokak Meridian
//                   <br />
//                   Business I-Blok Kapi No: 1 Kat:16 Daire:220 34746 Atasehir
//                   /Istanbul, Turkey
//                   <br />
//                   Telephone: 90-212223 0333
//                 </p>
//                 <p>
//                   Contact:
//                   <br />
//                   Cigdem Canse -{" "}
//                   <a href="mailto:ccanse@weconnor.com">ccanse@weconnor.com</a>
//                 </p>

//                 <h3>VIETNAM</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   Unit 9.2, 9th Floor, E Tower 2 Building, 364 Cong Hoa Street,
//                   <br />
//                   Tan Binh District, Ho Chi Minh City, Vietnam
//                   <br />
//                   Telephone: 84-28-3812-2784
//                 </p>
//                 <p>
//                   Contact:
//                   <br />
//                   Adrienne Bonne –{" "}
//                   <a href="mailto:adriennebonne@weconor.com">
//                     adriennebonne@weconor.com
//                   </a>
//                 </p>

//                 <h3>INDIA</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   Spazedge Commercial Tower, Tower – B, 1st Floor, Sector 47,
//                   <br />
//                   Sohna Road, Gurgaon, Haryana, PIN-122002, India
//                   <br />
//                   Telephone: 91-124-409-1200
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Ranjai Singh –{" "}
//                   <a href="mailto:rsingh@weconnor.com">rsingh@weconnor.com</a>
//                   <br />
//                   Naghma Zaidi –{" "}
//                   <a href="mailto:nzaidi@weconnor.com">nzaidi@weconnor.com</a>
//                 </p>

//                 <h3>INDONESIA</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   Menara Dea II 6th Floor, JL. Mega Kuningan Barat Kav.E.4.3.
//                   No.1-2,
//                   <br />
//                   Jakarta Selatan 12950, Indonesia
//                   <br />
//                   Telephone: 62-21-297-11200
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Jeffery Mark Rickert:{" "}
//                   <a href="mailto:jrickert@weconnor.com">
//                     jrickert@weconnor.com
//                   </a>
//                   <br />
//                   Leny Indrawaty –{" "}
//                   <a href="mailto:lindrawaty@weconnor.com">
//                     lindrawaty@weconnor.com
//                   </a>
//                 </p>

//                 <h3>PHILIPPINES</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   Unit B1, 9th Floor, 8 Rockwell, Rockwell Center,
//                   <br />
//                   Hidalgo Drive, Makati City, Philippines 1210
//                   <br />
//                   Telephone: 63-228249-8020
//                 </p>
//                 <p>
//                   Contacts:
//                   <br />
//                   Ren Calingo –{" "}
//                   <a href="mailto:rencalingo@weconnor.com">
//                     rencalingo@weconnor.com
//                   </a>
//                   <br />
//                   Tet Jarvina -{" "}
//                   <a href="mailto:tetjarvina@weconnor.com">
//                     tetjarvina@weconnor.com
//                   </a>
//                 </p>

//                 <h3>KOREA & SHANGHAI</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   8F, United Power International Plaza, No. 1158,
//                   <br />
//                   Yangpu District, Shanghai, China 200090
//                   <br />
//                   Telephone: 86-21-6510-1399
//                 </p>
//                 <p>
//                   Contact:
//                   <br />
//                   Ruiqing Wang –{" "}
//                   <a href="mailto:ruiqingwang@weconnor.com">
//                     ruiqingwang@weconnor.com
//                   </a>
//                 </p>

//                 <h3>THAILAND</h3>
//                 <strong>W. E. CONNOR & ASSOCIATES, LTD.</strong>
//                 <p>
//                   55 Soi Chidlom, Chidlom, Pathumwan,
//                   <br />
//                   Bangkok 10330, Thailand
//                   <br />
//                   Telephone: 66-2-654-9225
//                 </p>
//                 <p>
//                   Contact:
//                   <br />
//                   Tessa Prawet –{" "}
//                   <a href="mailto:tessaprawet@weconnor.com">
//                     tessaprawet@weconnor.com
//                   </a>
//                 </p>
//               </div>
//             </Tab>

//             <Tab eventKey="profile" title="Freight Forwarders">
//               <div style={{ padding: "20px" }}>
//                 <h2>Freight Forwarders Contacts</h2>

//                 <h3>Italy, Spain, and Portugal</h3>
//                 <strong>Albatrans, Inc.</strong>
//                 <p>
//                   Website:{" "}
//                   <a
//                     href="https://www.albatrans.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     www.albatrans.com
//                   </a>
//                   <br />
//                   For exact office location, please contact the Foreign Buying
//                   Office.
//                 </p>

//                 <h3>France (Paris Only), Netherlands, and Belgium</h3>
//                 <strong>Yusen Logistics</strong>
//                 <p>
//                   Website:{" "}
//                   <a
//                     href="https://www.yusen-logistics.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     www.yusen-logistics.com
//                   </a>
//                 </p>

//                 <h3>All Other Locations</h3>
//                 <strong>Expeditors International</strong>
//                 <p>
//                   Website:{" "}
//                   <a
//                     href="https://www.expeditors.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     www.expeditors.com
//                   </a>
//                 </p>

//                 <h3>Payment Information</h3>
//                 <p>
//                   For payment information, please contact the Foreign Buying
//                   Office listed above.
//                 </p>
//               </div>
//             </Tab>
//           </Tabs>
//         </div>
//       </div>
//       <div className="Scroll-down">
//         <ScrollToTop smooth color="#6f00ff" />
//       </div>
//     </div>
//   );
// };

// export default ContactsPage;


import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Tabs, Tab } from 'react-bootstrap';
import useContentful from "../../hooks/useContentful"; // Adjust the import path as needed // Ensure Bootstrap styles are imported
import './Contacts.css'; // Import your CSS file

const ContactsPage = () => {
  const { items: contacts, loading, error } = useContentful('contacts');

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>Error fetching contacts: {error.message}</div>;
  }

  // Check if contacts exist and render content
  if (contacts.length > 0) {
    const contact = contacts[0]; // Assuming you're fetching multiple contacts but only need the first one
    const { title, content, tabContents } = contact.fields; // Destructure fields

    return (
      <div className="contacts-container">
        {/* Render the main content */}
        {content && documentToReactComponents(content)}

        {/* Render Tabs for tab contents */}
        <Tabs defaultActiveKey={tabContents[0]?.fields.slug} id="contacts-tabs">
          {tabContents.map((tab) => (
            <Tab key={tab.fields.slug} eventKey={tab.fields.slug} title={tab.fields.tabTitle}>
              <div className="contacts-section">
                {/* Render the content of each tab */}
                {tab.fields.tabContent && documentToReactComponents(tab.fields.tabContent)}
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
    );
  }

  return <div>No contacts available.</div>;
};

export default ContactsPage;

