import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkPONumber } from "../../services/poGet";
import { Col } from "react-bootstrap";
import loaderGif from "../../assets/loder.gif";

function MerchandisePOVerification() {
  const [poNumbers, setPoNumbers] = useState("");
  const [poVerificationloading, setPoVerificationLoading] = useState(false);
  const [poError, setPoError] = useState("");

  const navigate = useNavigate();

  const handleVerificationSubmit = async (event) => {
    event.preventDefault();
    if (!poNumbers.trim()) {
      setPoError("This field is required");
      return;
    }
    const poNumbersArray = Array.from(
      new Set(
        poNumbers
          .split(/\n|,/) // Split by either comma or newline
          .map((po) => po.trim().toUpperCase()) // Trim and convert to uppercase
          .filter((po) => po) // Remove empty entries
      )
    );
  
    if (poNumbersArray.length > 20) {
      setPoError("You can enter up to 20 PO Numbers only.");
      return;
    }
    setPoVerificationLoading(true);

    try {
      // Validate PO Numbers using the checkPoNumber service
      const validationResults = await Promise.all(
        poNumbersArray.map(async (poNumber) => ({
          poNumber,
          isValid: await checkPONumber(poNumber),
        }))
      );

      const invalidPOs = validationResults
        .filter((result) => !result.isValid)
        //.map((result) => result.po);
        .map((result) => result.poNumber);

      if (invalidPOs.length > 0) {
        setPoError(`Invalid PO Numbers: ${invalidPOs.join(", ")}`);
        return;
      }

      // Fetch PO details
      const fetchedPoDetails = await Promise.all(
        poNumbersArray.map(async (po) => {
          try {
            const response = await checkPONumber(po);
            const poData = response?.data?.[0];

            if (poData) {
              return {
                po_number: po,
                start_ship_date:new Date(poData.start_ship_date).toISOString().split("T")[0],
                cancel_date: new Date(poData.cancel_date).toISOString().split("T")[0],
                po_status: poData.po_status,
                po_type: poData.po_type,
                chain: poData.chain,
                vendor_name: poData.vendor_name,
                supplier_site_id: poData.supplier_site_id,
                ship_from_zip: poData.ship_from_zip,
                zip:  poData.zip,
              };
            }

            return {
              po_number: po,
              error: "PO Not Found.",
            };
          } catch (error) {
            return {
              po_number: po,
              error: "Failed to fetch details",
            };
          } finally {
            setPoVerificationLoading(false);
          }
        })
      );
      const validResults = fetchedPoDetails.filter((po) => !po.error);

      if (validResults.length === 0) {
        setPoError("No valid PO numbers found.");
        return;
      }

      // Navigate to the verification page with PO details
      navigate("/verify-po", {
        state: {
          poSearchResultsMain: validResults,
          poNumbersMain: poNumbersArray,
        },
      });

      setPoNumbers("");
      setPoError("");
    } catch (error) {
      console.error("Error validating PO Numbers:", error);
      setPoError(
        "An error occurred while validating PO numbers. Please try again."
      );
    }
  };

  return (
    <Col md={4}>
      <div className="forms-column">
        <h3>Merchandise PO Verification</h3>
        <form onSubmit={handleVerificationSubmit}>
          {poError && <span className="error-message">{poError}</span>}
          {poVerificationloading && (
            <div className="loader-container">
              <img src={loaderGif} alt="Loading..." />
            </div>
          )}
          <div className="form-field">
            <textarea
              rows="5"
              placeholder="Enter up to 20 PO Numbers, one per line, to check the status of each PO."
              value={poNumbers}
              onChange={(e) => setPoNumbers(e.target.value)} // Update state as the user types
              className={poError ? "error" : ""}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </Col>
  );
}

export default MerchandisePOVerification;
