import React from "react";
import { useParams } from "react-router-dom"; // Get dynamic URL params
import useContentful from "../../hooks/useContentful";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const OperationalPortal = () => {
  const { bannerSlug } = useParams(); // Get the banner slug from the URL
  const { items: banners, loading: bannerLoading, error: bannerError } = useContentful("banner");

  if (bannerLoading) {
    return (
      <div className="content-page">
        <h1>
          <Skeleton width={300} />
        </h1>
        <Skeleton count={5} />
      </div>
    );
  }

  if (bannerError) {
    return <div>Error loading banner: {bannerError.message}</div>;
  }

  // Find the specific banner based on the URL parameter (slug)
  const selectedBanner = banners.find((banner) => banner.fields.slug === bannerSlug);

  return (
    <div className="content-page">
      {selectedBanner ? (
        <div className="banner-content">
          {documentToReactComponents(selectedBanner.fields.content)}
        </div>
      ) : (
        <p>No banner content available</p>
      )}
    </div>
  );
};

export default OperationalPortal;
