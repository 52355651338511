import { useEffect, useState } from 'react';
import { getContentfulClient } from "../contentfulClient";

const useContentful = (contentType, query = {}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const client = getContentfulClient();
  useEffect(() => {
    let didCancel = false; // To prevent state update on unmounted component

    console.log('Contentful client:', client); // Debug: Check client initialization

    
    const fetchData = async () => {
      setLoading(true);
      try {
        // console.info('in contentful=======> ');
        console.info(contentType);
        console.info(query);
        const response = await client.getEntries({
          content_type: contentType,
          include: 2, // Fetch related entries up to 2 levels deep
          ...query,
        });

        if (!didCancel) {
          setItems(response.items);
          console.log('Fetched entries:', response); // Debug: Log fetched items
        }
      } catch (err) {
        if (!didCancel) {
          setError(err);
          console.error('Error fetching entries:', err); // Debug: Log error
        }
      } finally {
        if (!didCancel) {
          setLoading(false);
        }
      }
    };

    fetchData();

    // Clean up function to avoid memory leaks
    return () => {
      didCancel = true;
    };
  }, [contentType, JSON.stringify(query)]);

  return { items, loading, error };
};

export default useContentful;
