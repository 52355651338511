import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./TabsComponent.css";
import Accordion from "react-bootstrap/Accordion";
import { getRenderOptions } from "../HelperFunctions/HelperFunctions";

const TabsComponent = ({ tabs, alignment = "normal", assets, entry }) => {
  if (!tabs) return null;

  return alignment === "Down" ? (
    <Accordion
      className="content-area-accordion"
      defaultActiveKey={tabs[0].sys.id}
      alwaysOpen
    >
      {tabs.map((tab, index) => (
        <Accordion.Item eventKey={index.toString()} key={tab.sys.id}>
          <Accordion.Header>{tab.fields.tabTitle}</Accordion.Header>
          <Accordion.Body>
            {documentToReactComponents(
              tab.fields.tabContent,
              getRenderOptions(tab.fields.alignment, assets, entry)
            )}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  ) : (
    <Tabs defaultActiveKey={tabs[0].fields.tabTitle} id="tabbed-content">
      {tabs.map((tab) => (
        <Tab
          key={tab.sys.id}
          eventKey={tab.fields.tabTitle}
          title={tab.fields.tabTitle}
        >
          <div className="mt-3" id={`tab-content-${tab.sys.id}`}>
            {documentToReactComponents(
              tab.fields.tabContent,
              getRenderOptions(tab.fields.alignment, assets, entry)
            )}
          </div>
        </Tab>
      ))}
    </Tabs>
  );
};

export default TabsComponent;
