import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "../../hooks/useContentful"; // Hook to fetch data from Contentful
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CodeOfConduct = () => {
    const { items: alerts, loading, error } = useContentful("codeOfConduct");

    // Handle loading state
    if (loading) {
        return (
            <div className="container">
                <h1>
                    <Skeleton width={300} />
                </h1>
                <div className="code-of-conduct-content">
                    <Skeleton count={5} />
                </div>
            </div>
        );
    }

    // Handle error state
    if (error) {
        return <div>Error loading Code of Conduct data: {error.message}</div>;
    }

    // Ensure there's at least one item in the response
    if (!alerts || alerts.length === 0) {
        return <div>No Code of Conduct data available.</div>;
    }

    // Access the title and content from the first item
    const data = alerts[0].fields;
    const title = data?.title || "No Title Available";
    const contentArray = data.content?.content || [];

    return (
        <div className="container my-5">
            <div className="description">
                <div class="customBorder">
                    <div className="Main-Title">
                        <h1>{title}</h1>
                        <div className="code-of-conduct-content">
                            {contentArray.length > 0 ? (
                                contentArray.map((paragraph, index) => (
                                    <div key={index} className="code-of-conduct-paragraph">
                                        {documentToReactComponents(paragraph)} {/* Render rich text */}
                                    </div>
                                ))
                            ) : (
                                <p>No content available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodeOfConduct;