import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPONumber } from "../../services/poGet";
import fetchRoutingRecords from "../../services/routingRecords";
import fetchPONumber from "../../services/poException";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import loader from "../../assets/loder.gif";

import { poTypesData } from "../MockData/PoTypeMockData";

const Division = [
  {
    id: 1,
    value: "Neiman Marcus Stores",
    label: "Neiman Marcus Stores",
  },
  {
    id: 3,
    value: "Neiman Marcus Direct",
    label: "Neiman Marcus Direct",
  },
  { id: 2, value: "Bergdorf Goodman", label: "Bergdorf Goodman" },
  { id: 4, value: "Last Call", label: "Last Call" },
];

const POVerificationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [poNumbers, setPoNumbers] = useState("");
  const [poError, setPoError] = useState("");
  const [poSearchResults, setPoSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zipCodes, setZipCodes] = useState({});
  const [fetchedPoDetails, setFetchedPoDetails] = useState([]);
  const [poNumbersMain, setPoNumbersMain] = useState([]);
  const [routingLoading, setRoutingLoading] = useState(false);

  const LoaderOverlay = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 1000,
      }}
    >
      <img
        src={loader}
        alt="Loading..."
        style={{
          width: "50px",
          height: "50px",
        }}
      />
    </div>
  );

  const getDivisionLabel = (chainValue) => {
    const divisionByValue = Division.find((div) => div.value === chainValue);
    if (divisionByValue) return divisionByValue.label;

    const divisionById = Division.find((div) => div.id === Number(chainValue));
    if (divisionById) return divisionById.label;

    return chainValue || "N/A";
  };

  useEffect(() => {
    const { poSearchResultsMain = [], poNumbersMain = [] } =
      location.state || {};

    if (poNumbersMain) {
      if (Array.isArray(poNumbersMain)) {
        setPoNumbers(poNumbersMain.join("\n"));
        setPoNumbersMain(poNumbersMain);
      } else if (typeof poNumbersMain === "string") {
        const poArray = poNumbersMain.split(",");
        setPoNumbers(poArray.join("\n"));
        setPoNumbersMain(poArray);
      }
    }

    if (poSearchResultsMain && poSearchResultsMain.length > 0) {
      setPoSearchResults(poSearchResultsMain);
    }
  }, [location.state]);

  const handleVerificationSubmit = async (event) => {
    event.preventDefault();
    if (!poNumbers.trim()) {
      setPoError("This field is required.");
      return;
    }

    const poNumbersArray = [
      ...new Set(
        poNumbers
          .split(/\n|,/)
          .map((po) => po.trim().toUpperCase())
          .filter((po) => po)
      ),
    ];

    if (poNumbersArray.length > 20) {
      setPoError("You can enter up to 20 PO numbers.");
      return;
    }

    setLoading(true);
    try {
      const fetchedPoDetails = await Promise.all(
        poNumbersArray.map(async (po) => {
          try {
            const response = await checkPONumber(po.toUpperCase());

            if (response?.data?.length > 0) {
              const poData = response.data[0];
              return {
                po_number: po,
                start_ship_date: new Date(poData.start_ship_date)
                  .toISOString()
                  .split("T")[0],
                cancel_date: new Date(poData.cancel_date)
                  .toISOString()
                  .split("T")[0],
                po_status: poData.po_status,
                po_type: poData.po_type,
                chain: poData.chain,
                chain_label: getDivisionLabel(poData.chain),
                vendor_name: poData.vendor_name,
                supplier_site_id: poData.supplier_site_id,
                ship_from_zip: poData.zip || "",
                zip: poData.zip || "",
              };
            }

            return {
              po_number: po,
              error: "PO Not Found.",
            };
          } catch (error) {
            return {
              po_number: po,
              error: "Failed to fetch details: " + error.message,
            };
          }
        })
      );

      setFetchedPoDetails(fetchedPoDetails);
      const validPoDetails = fetchedPoDetails.filter((po) => !po.error);

      if (validPoDetails.length === 0) {
        setPoError("All entered PO numbers are invalid.");
        setPoSearchResults([]);
      } else {
        setPoSearchResults(validPoDetails);
        setPoNumbersMain(poNumbersArray);
        setPoError("");
      }
    } catch (error) {
      console.error("Error during PO search:", error);
      NotificationManager.error("No records found for the entered PO numbers.");
    } finally {
      setLoading(false);
    }
  };

  const getDescription = (shipmentType) => {
    const foundType = poTypesData.poTypes.find(
      (item) => item.code === shipmentType
    );
    return foundType ? foundType.description : "N/A";
  };

  const handleClear = () => {
    setPoNumbers("");
    setPoError("");
    setPoSearchResults([]);
    setZipCodes({});
    setFetchedPoDetails([]);
  };

  const handleInternationalGuides = (poDetails) => {
    const encodedPONumber = encodeURIComponent(poDetails.po_number);

    navigate(`/po-international`, {
      state: {
        poData: poDetails,
        poNumber: encodedPONumber,
      },
    });
  };

  const handleRoutingGuideClick = async (po, zipCode, poDetails) => {
    setRoutingLoading(true);
    try {
      const currentZipCode =
        zipCodes[po] ||
        zipCode ||
        poDetails.zip ||
        poDetails.ship_from_zip ||
        "";
  
      if (!po) {
        NotificationManager.error("No PO number found");
        return;
      }
  
      const routingDataResponse = await fetchRoutingRecords(po, currentZipCode);
      const poExceptionDetails = await fetchPONumber(po);
      const poResponse = await checkPONumber(po);
  
      const poData = poResponse?.data?.[0] || {};
  
      // Construct routing data regardless of whether routingRecords is empty
      const routingData = {
        poNumber: po,
        vendorName:
          poDetails.vendor_name || poData.vendor_name || "Unknown Vendor",
        supplierSite:
          poDetails.supplier_site_id || poData.supplier_site_id || "N/A",
        department: poData.department_id || "N/A",
        shipFromZip: currentZipCode,
        startShipDate: new Date(poDetails.start_ship_date)
          .toISOString()
          .split("T")[0],
        cancelDate: new Date(poDetails.cancel_date).toISOString().split("T")[0],
        poType: poDetails.po_type || poData.po_type || "N/A",
        routingRecords: routingDataResponse?.data || [],
        poExceptionDetails: poExceptionDetails?.data || [],
      };
  
      // Always navigate to routing-details, even if routingRecords is empty
      navigate("/routing-details", {
        state: {
          routingData,
          poNumbers: poNumbersMain,
          poResponse,
        },
      });
    } catch (error) {
      console.error("Error fetching routing records:", error);
      NotificationManager.info("No records found for the entered PO numbers.");
    } finally {
      setRoutingLoading(false);
    }
  };

  const handleZipCodeChange = (po, value) => {
    setZipCodes((prevZipCodes) => ({
      ...prevZipCodes,
      [po]: value,
    }));
  };

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "20px" }}>
      {loading && <LoaderOverlay />}
      {routingLoading && <LoaderOverlay />}

      <div>
        <div
          style={{
            color: "#444",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "1.5",
            marginBottom: "20px",
          }}
        >
          <h2>The Neiman Marcus Group PO Verification</h2>
          <p className="header-description">
            This verification can be used to determine the status of your
            purchase orders prior to shipping. Please contact your buyer with
            any questions about your purchase order or if your purchase order is
            not found. Purchase Order information is updated nightly.
          </p>
          <p className="header-description">
            Note: If any purchase order in a shipment is invalid, the entire
            shipment will be refused from the carrier.
          </p>
          <p className="header-description">Enter up to 20 PO's, one per line, and click submit.</p>
        </div>
        <NotificationContainer />
        <form onSubmit={handleVerificationSubmit}>
          {poError && <span className="error-message">{poError}</span>}
          <div className="form-field" style={{ position: "relative" }}>
            <label
              htmlFor="po-number"
              style={{ display: "block", marginBottom: "10px" }}
            >
              PO Numbers
            </label>
            <div
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <textarea
                id="po-number"
                rows="5"
                placeholder="Enter up to 20 PO Numbers, one per line."
                value={poNumbers}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.includes(",")) {
                    const modifiedValue = inputValue
                      .split(/\n|,/)
                      .map((po) => po.trim())
                      .filter((po) => po !== "")
                      .join("\n");
                    setPoNumbers(modifiedValue);
                  } else {
                    setPoNumbers(inputValue);
                  }
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <button
              type="submit"
              style={{
                padding: "10px 15px",
                backgroundColor: "#AD3477",
                color: "white",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <img
                    src={loader}
                    alt="Loading"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Processing...
                </>
              ) : (
                "Submit"
              )}
            </button>
            <button type="button" className="clear-button" onClick={handleClear} disabled={loading}>
              Clear
            </button>
          </div>
        </form>

        {fetchedPoDetails?.filter((po) => po.error).length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <h3 style={{ color: "red" }}>Invalid Purchase Orders</h3>
            {fetchedPoDetails
              .filter((po) => po.error)
              .map((invalidPo) => (
                <p key={invalidPo.po_number} style={{ color: "red" }}>
                  {invalidPo.po_number}: {invalidPo.error}
                </p>
              ))}
          </div>
        )}

        {poSearchResults.length > 0 && (
          <div style={{ marginTop: "30px" }}>
            <h3>PO Search Results</h3>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    PO Number
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      minWidth: "160px",
                    }}
                  >
                    Start Ship Date
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      minWidth: "140px",
                    }}
                  >
                    Cancel Date
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    PO Status
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Shipment Type
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Chain
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Vendor Name
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Supplier Site
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px",minWidth: "140px" }}>
                    Ship From ZIP
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Routing Guide
                  </th>
                </tr>
              </thead>
              <tbody>
                {poSearchResults.map((poDetails) => (
                  <tr key={poDetails.po_number}>
                  <td style={{ border: "1px solid #ccc", padding: "10px", whiteSpace: "nowrap"}}>{poDetails.po_number}</td>
                    <td
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        minWidth: "120px",
                      }}
                    >
                      {poDetails.start_ship_date}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        minWidth: "120px",
                      }}
                    >
                      {poDetails.cancel_date}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.po_status}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {getDescription(poDetails.po_type)}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {getDivisionLabel(poDetails.chain)}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.vendor_name}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.supplier_site_id}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.po_status === "Approved" ? (
                        <div>
                          <input
                            type="text"
                            value={
                              zipCodes.hasOwnProperty(poDetails.po_number)
                                ? zipCodes[poDetails.po_number]
                                : poDetails.zip
                            }
                            disabled={
                              poDetails.po_type === "IM" ||
                              poDetails.po_type === "DS"
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === "" || value.length <= 5) {
                                handleZipCodeChange(poDetails.po_number, value);
                              }
                            }}
                            placeholder="Enter ZIP"
                            style={{ width: "100%" }}
                            maxLength={5}
                          />
                          {zipCodes[poDetails.po_number] &&
                            zipCodes[poDetails.po_number].length > 0 &&
                            zipCodes[poDetails.po_number].length < 5 && (
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "5px",
                                }}
                              >
                                ZIP code must be 5 digits
                              </span>
                            )}
                        </div>
                      ) : (
                        poDetails.zip
                      )}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.po_status === "Approved" ? (
                        <button
                        className="routing-guide-btn"
                          
                          disabled={routingLoading}
                          onClick={() =>
                            poDetails.po_type === "IM" ||
                            poDetails.po_type === "DS"
                              ? handleInternationalGuides(poDetails)
                              : handleRoutingGuideClick(
                                  poDetails.po_number,
                                  poDetails.zip,
                                  poDetails
                                )
                          }
                        >
                          {routingLoading ? (
                            <>
                              <img
                                src={loader}
                                alt="Loading"
                                style={{ width: "20px", height: "20px" }}
                              />
                              
                            </>
                          ) : poDetails.po_type === "IM" ||
                            poDetails.po_type === "DS" ? (
                            "View International Guide"
                          ) : (
                            "View Routing Guide"
                          )}
                        </button>
                      ) : (
                        <span style={{ color: "#888", fontStyle: "italic" }}>
                          Routing Guide link is only available for Approved PO
                          Numbers
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default POVerificationPage;
