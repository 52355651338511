import React, { useRef, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TabsComponent from "../TabsComponent/TabsComponent";
import useContentful from "../../hooks/useContentful";
import "./ContentPage.css";
import { get } from "lodash";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { format, parseISO } from "date-fns";
import { enUS } from "date-fns/locale";
import { FaFilePdf } from "react-icons/fa";
import {
  handlePrint,
  handleSaveAsPDF,
  options,
  getRenderOptions,
} from "../HelperFunctions/HelperFunctions";
import Domestic from "../../assets/domestic.png";
import { Link } from "react-router-dom";
import DocumentLibrary from "../DocumentLibrary/DocumentLibrary";
import { getPath } from "../../services/Utils";

const formatDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "MMM dd, yyyy, h:mm:ss a 'CDT'", { locale: enUS });
};

const extractShortDescription = (richTextContent) => {
  if (Array.isArray(richTextContent)) {
    const firstParagraph = richTextContent[0];
    const shortDescription =
      documentToReactComponents(firstParagraph).toString().split(".")[0] + ".";
    return <div>{shortDescription}</div>;
  }
  return null;
};

const ContentPage = ({ Id, type, descFlag = "false", desc = "" }) => {
  const {
    items: contentItems,
    loading,
    error,
  } = useContentful(type, { "sys.id": Id });

  const {
    items: alerts,
    loading: alertsLoading,
    error: alertsError,
  } = useContentful("alerts");

  // Adding console logs
  console.log("Alerts:", alerts);
  console.log("Alerts Loading:", alertsLoading);
  console.log("Alerts Error:", alertsError);

  const contentRef = useRef(null);
  const contentItem = contentItems[0];
  const Mainmenu = get(contentItem, "fields.title", null);
  console.log("mainmenutitle", Mainmenu);
  const Mainmenuslug = get(contentItem, "fields.slug", null);
  const { items: recentContents } = useContentful("contentPage", {
    order: "-sys.updatedAt",
  });

  const slugs = recentContents.map((content) =>
    get(content, "fields.parentSubMenu.fields.parentMenu.fields.slug", null)
  );

  const filteredRecentContents = recentContents
    .filter((recentContent, index) => slugs[index] === Mainmenuslug)
    .slice(0, 5); // Limit to the first 5 items

  console.log("filteredRecentContents:", filteredRecentContents);

  useEffect(() => {
    console.info("Content items retrieved:", contentItems);
  }, [contentItems]);

  if (loading || alertsLoading) {
    return (
      <div className="content-page">
        <h1>
          <Skeleton width={300} />
        </h1>
        <Skeleton count={5} />
      </div>
    );
  }

  if (error) return <div>Error loading content: {error.message}</div>;
  if (alertsError)
    return <div>Error loading alerts: {alertsError.message}</div>;

  if (!Array.isArray(contentItems) || contentItems.length === 0) {
    return <div>No content found for the provided ID.</div>;
  }

  const documentsAvailable = get(
    contentItem,
    "fields.documentsAvailable",
    false
  );
  console.log("des", contentItem);
  const tabContent = get(
    contentItem,
    "fields.tabContent[0].fields.tabContent.content[0].content[0]"
  );
  console.log("tabcontent", tabContent);
  const assests = [];

  return (
    <div>
      {descFlag === "false" && contentItem.fields.description ? (
        <div className="main-page">
          <div className="description">
            <div className="customBorder">
              <div className="Main-Title">
                <h1>{contentItem.fields.descriptionTitle}</h1>
                Last modified on {formatDate(contentItem.sys.updatedAt)}
              </div>
              <p></p>
              {documentToReactComponents(
                contentItem.fields.description,
                options(contentItem.fields.alignment, assests)
              )}
            </div>

            {/* Notifications Section */}
            <div className="notifications-section">
              <div className="right_side_image">
                <img src={Domestic} alt="Domestic Image" />
              </div>
              <h5 className="notifications_heading">Alerts</h5>
              <div className="notifications-box">
                {alerts && alerts.length > 0 ? (
                  alerts
                    .flatMap((alert) => alert.fields.tabs) // Combine all tabs from all alerts
                    .filter((tab) => {
                      const parentAlert = tab.fields.parentAlert || ""; // Default to empty string if undefined
                      const contentTitle = contentItem?.fields?.title || ""; // Default to empty string if undefined

                      return (
                        parentAlert.trim().toLowerCase() ===
                        contentTitle.trim().toLowerCase()
                      );
                    })
                    .slice(0, 3)
                    .map((tab, index) => {
                      const notificationDescription = tab.fields
                        .alertsDescription
                        ? documentToReactComponents(
                            tab.fields.alertsDescription
                          )
                        : null;

                      return (
                        <div key={index} className="notification-item">
                          <Link to="/alerts" className="no-decoration">
                            {tab.fields.title}:
                          </Link>
                          <div className="notification-description">
                            {notificationDescription ? (
                              notificationDescription.length > 0 ? (
                                notificationDescription[0]
                              ) : (
                                <p>No description available.</p>
                              )
                            ) : (
                              <p>No description available.</p>
                            )}
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <p>No notifications available.</p>
                )}
              </div>

              {/* Document Updates Section */}
             
      <h5 className="notifications_heading">Document Updates</h5>
      <div className="notifications-box-2">
        {filteredRecentContents.length > 0 ? (
          filteredRecentContents.map((recentContent, index) => {
            const title = get(recentContent, "fields.title", "Untitled Document");
            const content = get(
              recentContent,
              "fields.Contentdescription.content[0].content[0].value",
              null
            );
            const firstTenWords = content
              ? content.split(" ").slice(0, 10).join(" ")
              : "";
            const Slug = get(recentContent, "fields.slug", null);
            const parent = get(
              recentContent,
              "fields.parentSubMenu.fields.parentMenu.fields.slug",
              null
            );
            const Fetchpath = getPath(Slug, parent);

            return (
              <div key={index} className="update-item">
                <Link
                  to={`${Fetchpath}`}
                  className="no-decoration-2"
                >
                  {title}
                </Link>
                <div className="preview-text">{firstTenWords}</div>
              </div>
            );
          })
        ) : (
          <p>No document updates available.</p>
        )}
      </div>
            </div>
          </div>
        </div>
      ) : documentsAvailable ? (
        <DocumentLibrary />
      ) : (
        <div className="content-page">
          <div className="customBorder" ref={contentRef}>
            <h1>{contentItem.fields.title}</h1>
            Last modified on {formatDate(contentItem.sys.updatedAt)}
            <div className="d-flex justify-content-end mb-3">
              <button
                onClick={() =>
                  handleSaveAsPDF(contentRef, contentItem.fields.title)
                }
                className="btn btn-outline-danger me-2"
              >
                <FaFilePdf /> Save as PDF
              </button>
              <button
                onClick={() => handlePrint(contentRef)}
                className="btn btn-outline-primary pink_bg"
              >
                Print Tab Content
              </button>
            </div>
            {contentItem.fields.Contentdescription && descFlag === "false" && (
              <div className="contentpage-description">
                {documentToReactComponents(
                  contentItem.fields.Contentdescription,
                  getRenderOptions(contentItem.fields.alignment, assests)
                )}
              </div>
            )}
            {descFlag === "true" ? (
              <div className="content-description">
                {contentItem.fields.description ? (
                  <>
                    {documentToReactComponents(
                      contentItem.fields.description,
                      options(contentItem.fields.alignment, assests)
                    )}

                    {contentItem.fields.tabContent &&
                      contentItem.fields.tabContent.length > 0 && (
                        <TabsComponent
                          tabs={contentItem.fields.tabContent}
                          alignment={get(
                            contentItem,
                            "fields.alignemtoftabs",
                            "normal"
                          )}
                          type="tabcontent"
                          assets={[]}
                        />
                      )}
                  </>
                ) : (
                  <h2>Description not available for this content item.</h2>
                )}
              </div>
            ) : (
              <TabsComponent
                tabs={contentItem.fields.tabs}
                alignment={get(contentItem, "fields.alignemtoftabs", "normal")}
                type="contentPage"
                assets={[]}
                entry={[]}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentPage;
