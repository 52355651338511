import { useState, useEffect } from 'react';
import { getContentfulClient } from "../contentfulClient";

const useGroupedContent = (contentType) => {
  const [items, setItems] = useState([]);
  const client = getContentfulClient();

  useEffect(() => {
    console.log('useGroupedContent hook triggered');  // Debugging: Log when the useEffect is triggered

    client.getEntries({ content_type: contentType, include: 6})  // Adjust 'include' as needed
      .then((response) => {
        console.log('Grouped Contentful Response:', response);  // Log the response from Contentful
        if (response.items) {
          setItems(response.items);  // Update state only when needed
        } else {
          console.error('Unexpected response format:', response);
          setItems([]);  // Handle unexpected format
        }
      })
      .catch((error) => {
        console.error('Error fetching data from Contentful:', error);
        setItems([]);
      });
  }, [contentType]);  // Ensure useEffect runs only when contentType changes

  return items;
};

export default useGroupedContent;
