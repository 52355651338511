// import React, { useState, useEffect, useMemo } from 'react';
// import { useLocation } from 'react-router-dom';
// import useContentful from "../../hooks/useContentful";
// import { Container, Form } from 'react-bootstrap';
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// import { BLOCKS, MARKS } from "@contentful/rich-text-types";
// import { getRenderOptions } from "../HelperFunctions/HelperFunctions";
// import "./Alerts.css";

// // Sidebar Component
// const Sidebar = ({ alerts, activeTab, onTabClick, filter }) => (
//   <div className="sidebar" >
//     {alerts.map((alertItem, index) => (
//       <div key={alertItem.sys.id}>
//         <h3>{alertItem.fields.title || 'Alerts'}</h3>
//         <ul>
//           {alertItem.fields.tabs
//             .filter(tab => !filter || (tab.fields.parentAlert && tab.fields.parentAlert.toLowerCase().includes(filter)))
//             .map((tab, tabIndex) => (
//               <li
//                 key={tabIndex}
//                 onClick={() => onTabClick(index, tabIndex)}
//                 className={
//                   index === activeTab.alertIndex && tabIndex === activeTab.tabIndex
//                     ? 'active-tab'
//                     : 'inactive-tab'
//                 }
//               >
//                 {tab.fields.title}
//               </li>

//             ))}
//         </ul>
//       </div>
//     ))}
//   </div>
// );

// // Alert Content Component
// const AlertContent = ({ currentAlert, options }) => {
//   if (!currentAlert) return <p>No alert selected</p>;
//   if (!currentAlert.description) return <p>No description available for this alert.</p>;

//   try {
//     return (
//       <>
//         <h2>{currentAlert.title}</h2>
//         {documentToReactComponents(currentAlert.description, options)}
//       </>
//     );
//   } catch (error) {
//     console.error("Error rendering rich text:", error);
//     return <p>Error rendering alert description. Please check the console for more details.</p>;
//   }
// };

// // Filter Component
// const Filter = ({ onFilterChange }) => (
//   <div className="filter_box">
//     <Form.Control
//       type="text"
//       placeholder="Filter"
//       onChange={onFilterChange}
//     />
//   </div>
// );

// // Main AlertsPage Component
// const AlertsPage = () => {
//   const location = useLocation();
//   const { state } = location;
//   const { activeTab: initialActiveTab = { alertIndex: 0, tabIndex: 0 } } = state || {};

//   const { items: alertsa, error: alertsaError } = useContentful("alerts");

//   const [activeTab, setActiveTab] = useState(initialActiveTab);
//   const [filter, setFilter] = useState('');

//   const filteredAlerts = useMemo(() =>
//     alertsa ? alertsa.filter(alertItem =>
//       alertItem.fields.tabs.some(tab =>
//         tab.fields.parentAlert && tab.fields.parentAlert.toLowerCase().includes(filter)
//       )
//     ) : [], [alertsa, filter]
//   );

//   const currentAlert = useMemo(() => {
//     if (!filteredAlerts.length) return null;
//     const alertItem = filteredAlerts[activeTab.alertIndex];
//     if (!alertItem) return null;
//     const tab = alertItem.fields.tabs[activeTab.tabIndex];
//     if (!tab) return null;
//     return {
//       title: tab.fields.title,
//       description: tab.fields.alertsDescription,
//       parentAlert: tab.fields.parentAlert
//     };
//   }, [filteredAlerts, activeTab]);

//   const handleTabClick = (alertIndex, tabIndex) => {
//     setActiveTab({ alertIndex, tabIndex });
//   };

//   const handleFilterChange = (e) => {
//     setFilter(e.target.value.toLowerCase());
//   };

//   if (alertsaError) return <div>Error loading alerts: {alertsaError.message}</div>;

//   const options = {
//     renderMark: {
//       [MARKS.BOLD]: (text) => <strong>{text}</strong>,
//       [MARKS.ITALIC]: (text) => <em>{text}</em>,
//       [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
//     },
//     renderNode: {
//       [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
//       [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
//       [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
//       [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
//       [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
//       [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
//     },
//   };

//   return (
//     <div className="alert_wrapper">
//       <div className="sidebar_and_content">
//         <Sidebar
//           alerts={filteredAlerts}
//           activeTab={activeTab}
//           onTabClick={handleTabClick}
//           filter={filter}
//         />
//         <div className="alerts-content">
//           <div className="border_area">
//             <Filter onFilterChange={handleFilterChange} />
//             <AlertContent currentAlert={currentAlert} options={options} />
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// };

// // Styles
// // const sidebarStyle = {
// //   width: "250px",
// //   backgroundColor: "#f8f9fa",
// //   padding: "20px",
// //   boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
// //   overflowY: "auto",
// //   height: "calc(100vh - 56px)", // Adjust based on your navbar height
// // };

// // const tabListStyle = {
// //   listStyleType: "none",
// //   paddingLeft: "0",
// // };

// // const tabItemStyle = {
// //   padding: "5px 0",
// //   fontSize: "0.9rem",
// //   cursor: 'pointer',
// // };

// // const contentStyle = {
// //   padding: "20px",
// //   flex: 1,
// // };

// export default AlertsPage;



import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useContentful from "../../hooks/useContentful";
import { Container, Form } from 'react-bootstrap';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { getRenderOptions } from "../HelperFunctions/HelperFunctions";
import "./Alerts.css";

// Sidebar Component
const Sidebar = ({ alerts, activeTab, onTabClick, filter }) => (
  <div className="sidebar">
    {alerts.map((alertItem, index) => (
      <div key={alertItem.sys.id}>
        <h3>{alertItem.fields.title || 'Alerts'}</h3>
        <ul>
          {alertItem.fields.tabs
            .filter(tab => !filter || (tab.fields.parentAlert && tab.fields.parentAlert.toLowerCase().includes(filter)))
            .map((tab, tabIndex) => (
              <li
                key={tabIndex}
                onClick={() => onTabClick(index, tabIndex)}
                className={
                  index === activeTab.alertIndex && tabIndex === activeTab.tabIndex
                    ? 'active-tab'
                    : 'inactive-tab'
                }
              >
                {tab.fields.title}
              </li>
            ))}
        </ul>
      </div>
    ))}
  </div>
);

const ContentfulImage = ({ asset }) => {
  if (!asset || !asset.fields) return null;
  
  return (
    <div className="contentful-image-wrapper">
      <img
        src={asset.fields.file.url}
        alt={asset.fields.description || asset.fields.title || 'Alert image'}
        className="contentful-image"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      {asset.fields.description && (
        <p className="image-caption text-sm text-gray-600 mt-2">
          {asset.fields.description}
        </p>
      )}
    </div>
  );
};

// Alert Content Component
const AlertContent = ({ currentAlert, options }) => {
  if (!currentAlert) return <p>No alert selected</p>;
  
  if (!currentAlert.description) return <p>No description available for this alert.</p>;

  const renderOptions = {
    ...options,
    renderNode: {
      ...options.renderNode,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = node.data.target;
        return <ContentfulImage asset={asset} />;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-4">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-2xl font-bold mb-4">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-xl font-bold mb-3">{children}</h2>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc pl-6 mb-4">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal pl-6 mb-4">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="mb-2">{children}</li>
      ),
    },
  };

  try {
    return (
      <div className="alert-content-wrapper">
        <h2 className="alert-title">{currentAlert.title}</h2>
        {currentAlert.image && (
          <ContentfulImage asset={currentAlert.image} />
        )}
        <div className="alert-description">
          {documentToReactComponents(currentAlert.description, renderOptions)}
        </div>
      </div>
    );
  } catch (error) {
    console.error("Error rendering rich text:", error);
    return <p>Error rendering alert description. Please check the console for more details.</p>;
  }
};

// Filter Component
const Filter = ({ onFilterChange }) => (
  <div className="filter_box">
    <Form.Control
      type="text"
      placeholder="Filter"
      onChange={onFilterChange}
    />
  </div>
);

// Main AlertsPage Component
const AlertsPage = () => {
  const location = useLocation();
  const { state } = location;
  const { activeTab: initialActiveTab = { alertIndex: 0, tabIndex: 0 } } = state || {};

  const { items: alertsa, error: alertsaError } = useContentful("alerts");

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [filter, setFilter] = useState('');

  const filteredAlerts = useMemo(() =>
    alertsa ? alertsa.filter(alertItem =>
      alertItem.fields.tabs.some(tab =>
        tab.fields.parentAlert && tab.fields.parentAlert.toLowerCase().includes(filter)
      )
    ) : [], [alertsa, filter]
  );

  const currentAlert = useMemo(() => {
    if (!filteredAlerts.length) return null;
    const alertItem = filteredAlerts[activeTab.alertIndex];
    if (!alertItem) return null;
    const tab = alertItem.fields.tabs[activeTab.tabIndex];
    if (!tab) return null;
    return {
      title: tab.fields.title,
      description: tab.fields.alertsDescription,
      parentAlert: tab.fields.parentAlert,
      image: tab.fields.image // Add image field
    };
  }, [filteredAlerts, activeTab]);

  const handleTabClick = (alertIndex, tabIndex) => {
    setActiveTab({ alertIndex, tabIndex });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  if (alertsaError) return <div>Error loading alerts: {alertsaError.message}</div>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    },
  };

  return (
    <div className="alert_wrapper">
      <div className="sidebar_and_content">
        <Sidebar
          alerts={filteredAlerts}
          activeTab={activeTab}
          onTabClick={handleTabClick}
          filter={filter}
        />
        <div className="alerts-content">
          <div className="border_area">
            <Filter onFilterChange={handleFilterChange} />
            <AlertContent currentAlert={currentAlert} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsPage;