import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "",
  headers: {
    'Content-Type': 'application/json'
  },
});

// Function to update axios instance dynamically when config is available
export const updateAxiosConfig = (config) => {
  if (config?.BASE_URL) {
    axiosInstance.defaults.baseURL = config.BASE_URL;
    axiosInstance.defaults.headers.common['x-api-key'] = config.API_KEY;
  }
};

// Custom interceptor to delay requests until config is available
axiosInstance.interceptors.request.use(
  async (request) => {
    if (!axiosInstance.defaults.baseURL) {
      console.warn("Axios instance is not configured yet, waiting for config...");
      await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay
    }
    return request;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
