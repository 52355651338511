//Used for home screen page of preticket search form
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { checkPONumber } from "../../services/poGet";
import loaderGif from "../../assets/loder.gif";

const PreTicketSearchForm = () => {
  const [poNumbersForPreTicket, setPoNumbersForPreTicket] = useState("");
  const [poPreTicketError, setPoPreTicketError] = useState("");
  const [preTicketloading, setPreTicketLoading] = useState(false);
  const navigate = useNavigate();

  const handlePreTicketSubmit = async (event) => {
    event.preventDefault();

    if (!poNumbersForPreTicket.trim()) {
      setPoPreTicketError("This field is required.");
      return;
    }

    const poNumbersArray = poNumbersForPreTicket
      .split("\n")
      .map((po) => po.trim())
      .filter((po) => po);

    if (poNumbersArray.length > 10) {
      setPoPreTicketError("You can enter up to 10 PO numbers.");
      return;
    }

    setPoPreTicketError("");
    setPreTicketLoading(true);

    try {
      const fetchedPoDetails = await Promise.all(
        poNumbersArray.map(async (po) => {
          try {
            const response = await checkPONumber(po);
            const poData = response.data?.[0];
            if (poData) {
              return {
                po_number: po,
                cancel_date: poData.cancel_date,
                fedex_number: poData.fedex_number,
                freight: poData.freight,
                ticket_cost:poData.ticket_cost
             
              };
            } else {
              return { po_number: po, error: "Invalid PO number" };
            }
          } catch (error) {
            console.error(`Error fetching PO details for ${po}:`, error);
            return { po_number: po, error: "Error fetching details" };
          }
        })
      );

      const validResults = fetchedPoDetails.filter((po) => !po.error);

      if (validResults.length === 0) {
        setPoPreTicketError("No valid PO numbers found.");
        return;
      }

      navigate("/pre-ticket-search", {
        state: { validPoDetails: validResults },
      });

      setPoNumbersForPreTicket("");
    } catch (error) {
      console.error("Error during PO search:", error);
      setPoPreTicketError("Error fetching PO details. Please try again.");
    } finally {
      setPreTicketLoading(false);
    }
  };

  return (
    <Col md={4}>
      <div className="forms-column">
        <h3>PreTicket Search</h3>
        <form onSubmit={handlePreTicketSubmit}>
          {poPreTicketError && (
            <span className="error-message">{poPreTicketError}</span>
          )}
          <div className="form-field">
            <textarea
              rows="5"
              cols={40}
              placeholder="Enter up to 20 PO Numbers, one per line, to search the Po details"
              value={poNumbersForPreTicket}
              onChange={(e) => {
                setPoNumbersForPreTicket(e.target.value);
                if (e.target.value.trim()) setPoPreTicketError("");
              }}
              className={poPreTicketError ? "error" : ""}
            />
          </div>
          {preTicketloading && (
            <div className="loader-container">
              <img src={loaderGif} alt="Loading..." />
            </div>
          )}
          <button type="submit">Submit</button>
        </form>
      </div>
    </Col>
  );
};

export default PreTicketSearchForm;
