import axiosInstance from "./AxiosInstance";

const fetchRoutingRecords = async (poNumber,zipcode) => {
  try {
    const response = await axiosInstance.get('/routingRecordsPublicPortal', {
      params: { poNumber,zipcode }, 
    });
    return response.data; 
  } catch (error) {
    console.error("Error checking PO number:", error.response?.data || error.message);
    throw new Error("PO number not found"); 
  }
};

export default fetchRoutingRecords;
