import React from 'react';
import { Row } from 'react-bootstrap';
import BannerSlider from '../BannerSlider/BannerSlider';
import MerchandisePOVerification from '../MerchandisePoVerificationForm/MerchandisePoVerificationForm';
import MerchandiseRoutingForm from '../MerchandiseRoutingForm/MerchandiseRoutingForm';
import PreTicketSearchForm from '../PreTicketSearchForm/PreTicketSearchForm';

const HomePage = () => {
  return (
    <>
      <BannerSlider />
      <div className="HomeFormWrapper">
        <Row className="justify-content-between align-items-center">
          <MerchandisePOVerification />
          <MerchandiseRoutingForm />
          <PreTicketSearchForm />
        </Row>
      </div>
    </>
  );
};

export default HomePage;