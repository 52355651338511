// src/components/AppRouter/AppRouter.js
import React from "react";
import { Route, Routes,Navigate } from "react-router-dom";
import { addRoute } from "../../services/Utils";
import ContentPage from "../ContentPage/ContentPage";
import Alerts from "../Alerts/Alerts";
import Contacts from "../Contacts/Contacts";
import OperationalPortal from "../OperationsPortal/OperationsPortal";
import PoVerification from "../PoVerification/PoVerification";
import PreTicketSearch from "../PreTicketSearch/PreTicketSearch";
import RoutingGuideusingSupplier from "../RoutingGuideusingSupplier/RoutingGuideusingSupplier";
import POVerificationPage from "../MerchandisePoVerification/MerchandisePoVerification";
import PORoutingGuidePage from "../RoutingGuideUsingPo/RoutingGuideUsingPo";
import ZipRoutingDetailsPage from "../ZipRoutingDetails/ZipRoutingDetails";
import POInternationalPage from "../../POInternationalPage";
import Privacy from "../Footer/Privacy";
import CodeOfConduct from "../Footer/CodeOfConduct";
import PoLegalTerms from "../Footer/PoLegalTerms";
import PoVerificationForm from "../PoVerification/PoVerification";
import HomePage from "../HomePage/HomePage";
import StoresAbbrevationPage from "../StoresAbbrevationPage/StoresAbbrevationPage";

const AppRouter = ({ menuItems }) => {
  // Function to generate dynamic routes from menu items
  const generateRoutes = (menu, parentPath = "") => {
    let routes = [];

    if (menu.fields?.subMenus) {
      menu.fields.subMenus.forEach((subMenu) => {
        const currentPath = `${parentPath}/${subMenu.fields?.slug}`;
        const hasDescription = !!subMenu.fields.description;

        // Add route to the routesList
        addRoute(currentPath);

        if (hasDescription) {
          routes.push(
            <Route
              key={`${subMenu.sys.id}-desc`}
              path={currentPath}
              element={
                <ContentPage
                  Id={subMenu.sys.id}
                  type="subMenu"
                  descFlag="true"
                />
              }
            />
          );
        } else if (
          subMenu.fields?.contentPages &&
          subMenu.fields.contentPages.length > 0
        ) {
          const firstContentPage = subMenu.fields.contentPages[0];
          routes.push(
            <Route
              key={firstContentPage.sys.id}
              path={currentPath}
              element={
                <ContentPage Id={firstContentPage.sys.id} type="contentPage" />
              }
            />
          );
        }

        if (subMenu.fields?.contentPages) {
          subMenu.fields.contentPages.forEach((contentPage) => {
            const contentPath = `${currentPath}/${contentPage.fields?.slug}`;
            // Add content page route to the routesList
            addRoute(contentPath);

            routes.push(
              <Route
                key={contentPage.sys.id}
                path={contentPath}
                element={
                  <ContentPage Id={contentPage.sys.id} type="contentPage" />
                }
              />
            );
          });
        }

        routes = routes.concat(generateRoutes(subMenu, currentPath));
      });
    }

    return routes;
  };

  // Generate dynamic routes from menu items using memoization
  const dynamicRoutes = React.useMemo(() => {
    if (!menuItems) return [];

    let routes = [];
    menuItems.forEach((mainMenu) => {
      const mainMenuSlug = mainMenu.fields?.slug;
      if (mainMenuSlug) {
        const mainMenuPath = `/${mainMenuSlug}`;
        // Add main menu route to the routesList
        addRoute(mainMenuPath);

        routes.push(
          <Route
            key={mainMenu.sys.id}
            path={mainMenuPath}
            element={<ContentPage Id={mainMenu.sys.id} type="mainMenu" />}
          />
        );

        routes = routes.concat(generateRoutes(mainMenu, mainMenuPath));
      }
    });

    return routes;
  }, [menuItems]);

  // Main router component
  return (
    <Routes>
      {/* Static Routes */}
      <Route path="/" element={<HomePage />} />

      <Route path="/alerts" element={<Alerts />} />

      <Route
        path="/RoutingGuideusingSupplier"
        element={<RoutingGuideusingSupplier />}
      />

      <Route path="/contacts" element={<Contacts />} />

      <Route path="/operational-portal" element={<OperationalPortal />} />

      <Route path="/po-verification" element={<PoVerification />} />

      <Route path="/pre-ticket-search" element={<PreTicketSearch />} />

      <Route path="/stores-abbreviation" element={<StoresAbbrevationPage />} />

      {/* Dynamic Routes generated from Contentful */}
      {dynamicRoutes}

      {/* PO Related Routes */}
      <Route path="/verify-po" element={<POVerificationPage />} />

      <Route path="/routing-details" element={<ZipRoutingDetailsPage />} />

      <Route path="/po-international" element={<POInternationalPage />} />

      <Route path="/po-routing-guide" element={<PORoutingGuidePage />} />

      {/* Footer Routes */}
      <Route path="/privacy" element={<Privacy />} />

      <Route path="/codeOfConduct" element={<CodeOfConduct />} />

      <Route path="/poLegalTerms" element={<PoLegalTerms />} />

      <Route path="/po-verification" element={<PoVerificationForm />} />

      {/* 404 Route */}

      {/* Other routes */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;
