//This is second page of Merchandise ROuting Guide

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./RoutingGuideUsingPo.css";
import fetchRoutingRecords from "../../services/routingRecords";
import fetchPONumber from "../../services/poException";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const PORoutingGuidePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { poNumber, poData } = location.state || {};

  const [shipFromZip, setShipFromZip] = useState("");
  console.log("ponuber-------", poNumber);

  const data = poData.data ? poData.data[0] : "No data available";
  // Mock function to fetch zip code based on the PO number
  const getZipCodeByPO = (poData, poNumber) => {
    // Ensure poData and poData.data are valid
    if (poData && poData.data && Array.isArray(poData.data)) {
      // Find the PO data by matching poNumber (assuming poNumber is in poData)
      const poEntry = poData.data.find((item) => item.po_number === poNumber);

      if (poEntry) {
        console.log("poEntry============", poEntry);
        return poEntry.zip || "";
      }
    }

    return "";
  };

  useEffect(() => {
    if (poNumber) {
      const zipCode = getZipCodeByPO(poData, poNumber);
      setShipFromZip(zipCode);
    }
    console.log("shipFromZip", shipFromZip);
  }, [poNumber, poData]);

  const vendorData = {
    vendorName: data.vendor_name,
    supplierSite: data.supplier_site_id,
    department: data.department_id,
  };

  const formattedData = `PO Number: ${poNumber || "N/A"}\nVendor Name: ${
    vendorData.vendorName
  }\nSupplier Site: ${vendorData.supplierSite}\nDepartment: ${
    vendorData.department
  }`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitted Ship From Zip:", shipFromZip);

    // Function to fetch routing records based on PO number
    const fetchRoutingDetails = async (poNumber,shipFromZip) => {
      try {
        console.log("Fetching routing records for PO:", poNumber,shipFromZip);
        
        const response = await fetchRoutingRecords(poNumber,shipFromZip); // Ensure this function is defined and imported
        if (response?.data) {
          console.log("Fetched Routing Records........:", response.data);
          return response.data; // Return API data
        } else {
          console.warn("No routing records returned from API.");
          return [];
        }
      } catch (error) {
        console.error("Error fetching routing records:", error);
        return [];
      }
    };

    // Fetch PO exception details
    const fetchPOExceptionDetails = async (poNumber) => {
      try {
        const response = await fetchPONumber(poNumber); // Ensure this function is defined and imported
        if (response?.data) {
          console.log(
            "Fetched PO Exception Details+++++++++++:",
            response.data
          );
          return response.data || null; // Return the ID if present
        } else {
          console.warn("No PO exception details returned from API.");
          return null;
        }
      } catch (error) {
        console.error("Error fetching PO exception details:", error);
        return null;
      }
    };

    try {
      const [routingDetails, poExceptionDetails] = await Promise.all([
        fetchRoutingDetails(poNumber,shipFromZip),
        fetchPOExceptionDetails(poNumber),
      ]);
      // if (!routingDetails || routingDetails.length === 0) {
      //   NotificationManager.info(
      //     "No routing records found for this PO number.",
      //     "info"
      //   );
      //   return;
      // }
      const startShipDate = data?.start_ship_date || "N/A";
      const cancelDate = data?.cancel_date || "N/A";
      const poType = data?.po_type || "N/A";
      const poStatus = data?.po_status || "N/A";

      // Construct routing data for navigation
      const routingData = {
        poNumber,
        vendorName: vendorData.vendorName || "Unknown Vendor",
        supplierSite: vendorData.supplierSite || "N/A",
        department: vendorData.department || "N/A",
        shipFromZip: shipFromZip || "N/A",
        startShipDate,
        cancelDate,
        poType,
        poStatus,
        routingRecords: routingDetails,
        poExceptionDetails: poExceptionDetails,
      };
      console.log("routingData''''''''''", routingData);

      navigate("/routing-details", {
        state: { routingData },
        componentSource: "RoutingGuideUsingPo",
      });
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  // Function to navigate back to the integrated merchandise routing guides
  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="porouting-guide-page">
      <NotificationContainer></NotificationContainer>
      <h3>The Neiman Marcus Group Routing Guide</h3>
      <button onClick={handleBack} className="nav-button">
        <span className="arrow-icon">&larr;</span>
        <span className="button-text">ROUTING HOME</span>
      </button>
      <div>
        <div className="center-wrapper">
          <div className="formatted-data">{formattedData}</div>
        </div>

        <p>
          <b className="header-description">
            Verify the 'Ship From Zip' shown below is the proper shipping
            location zip code. If necessary, modify the zip code shown. Then
            click on the 'Submit' button to view the routing instructions
            specific to this PO and 'Ship From Zip'.
          </b>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <label htmlFor="shipFromZip">Ship From Zip:</label>
            <input
              type="text"
              id="shipFromZip"
              value={shipFromZip}
              onChange={(e) => setShipFromZip(e.target.value)}
              placeholder="Zip"
              required
            />
            <button type="submit" className="submit-button">
              Submit
            </button>
          </div>
        </form>

        <div>
          <br />
          <p className="header-description">
            For General Routing and Shipping Instructions please{" "}
            <Link
              to="/domestic/transportation/general-routing-and-shipping-instructions"
              style={{ color: "blue", textDecoration: "none" }}
            >
              (Click Here)
            </Link>{" "}
            or call the Neiman Marcus Transportation Department at 469-262-1255
          </p>
        </div>
      </div>
    </div>
  );
};

export default PORoutingGuidePage;
