
import axiosInstance from "./AxiosInstance";
const fetchPONumber = async (poNumber) => {
    try {
      const response = await axiosInstance.get('/exceptions', {
        params: { poNumber },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching PO by number:', error);
      throw error;
    }
  };

  export default fetchPONumber;