import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Container,
  Alert,
  Spinner,
} from "react-bootstrap";
import fetchRoutingRecordsusingSupplier from "../../services/routingGuidesusingsupplier";
import { se } from "date-fns/locale";
 
const RoutingGuidePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { supplierData } = location.state || {};
  console.log("Supplier Data:-----------------------------", supplierData);
  const supplierDetails = supplierData?.data?.[0] || {};
  console.log("supplierDetails=======================", supplierDetails);
 
  const [formData, setFormData] = useState({
    chainArea: supplierDetails.chainArea || supplierDetails.area || "",
    brand_id: supplierDetails.brand_id || supplierDetails.brand || "",
    shipFromZip: supplierDetails.shipFromZip || supplierDetails.zip || "",
    chain: supplierDetails.chain || "",
    department: supplierDetails.department || "",
    supplierSite: supplierDetails.supplierSite || "",
    vendorName: supplierDetails.vendorName || "",
  });

  console.log("Form Data:", formData);

 
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
 
  const chainOptions = React.useMemo(() => {
    if (!supplierData?.data) return [];
 
    const getChainLabel = (chain, area) => {
      if (chain === 1) {
        if (area === 12) return "Neiman Marcus Store";
        if (area === 13) return "Neiman Marcus Direct";
      }
      if (chain === 2 && area === 0) return "Bergdorf Goodman";
      if (chain === 4 && area === 0) return "Last Call";
      return "";
    };
 
    const uniqueChainAreas = new Set(
      supplierData.data.map(item => `${item.chain}:${item.area}`)
    );
 
    return Array.from(uniqueChainAreas)
      .map(chainArea => {
        const [chain, area] = chainArea.split(":").map(Number);
        const label = getChainLabel(chain, area);
        return label ? { value: chainArea, label } : null;
      })
      .filter(Boolean);
  }, [supplierData]);
 
  const brandOptions = React.useMemo(() => {
    if (!supplierData?.data) return [];
   
    const uniqueBrands = new Set(
      supplierData.data.map(item => item.brand_id)
    );
 
    return Array.from(uniqueBrands).map(brand => ({
      value: brand.toString(),
      label: supplierData.data.find(item => item.brand_id === brand)?.brand_desc || brand.toString()
    }));
  }, [supplierData]);
 
  const handleInputChange = (field, value) => {
    setValidationError("");
    setFormData(prev => ({
      ...prev,
      [field]: value,
      ...(field === "chainArea" ? { brand_id: "" } : {})
    }));
  };
 
  const validateZipCode = (zipCode) => {
    const zipRegex = /^\d{5}(-\d{4})?$/;
    return zipRegex.test(zipCode);
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationError("");
 
    if (!formData.chainArea || !formData.brand_id) {
      setValidationError("Please select both chain and brand.");
      return;
    }
 
    if (!formData.shipFromZip) {
      setValidationError("Please enter Ship From Zip");
      return;
    }
 
    if (!validateZipCode(formData.shipFromZip)) {
      setValidationError("Please enter a valid zip code (e.g., 12345 or 12345-6789)");
      return;
    }
 
    setLoading(true);
 
    try {
      const [chain, area] = formData.chainArea.split(":").map(Number);
      const selectedRecord = supplierData.data.find(item =>
        item.chain === chain && item.area === area
      );
 
      if (!selectedRecord) {
        setValidationError("Selected combination not found.");
        return;
      }
      const commodityCode = selectedRecord.commodity_code_id;
 
      const routingResponse = await fetchRoutingRecordsusingSupplier(
        selectedRecord.supplier_site_id,
        commodityCode,
        formData.shipFromZip,
        chain,
        area,
        selectedRecord.dept_code
       
      );
 
      console.log("routing respoisne////////////////// ",routingResponse);
 
      if (!routingResponse.records || routingResponse.records.length === 0) {
        setValidationError("No routing records found for the selected criteria.");
        return;
      }
 
      const routingData = {
        vendorName: selectedRecord.vendor_name,
        supplierSite: selectedRecord.supplier_site_id,
        department: selectedRecord.dept_code,
        shipFromZip: formData.shipFromZip,
        startShipDate: selectedRecord.start_ship_date || "N/A",
        cancelDate: selectedRecord.cancel_date || "N/A",
        poType: selectedRecord.po_type || "N/A",
        poStatus: selectedRecord.po_status || "N/A",
        chain: selectedRecord.chain,
        area: selectedRecord.area,
        brand: formData.brand_id,
        routingRecords: routingResponse.records,
        routingGuide: routingResponse.routingGuide,
        zip: selectedRecord.zip,
        commodityCode:routingResponse.routingGuide.commodity_code_id
       
      };
      
 
      navigate("/routing-details", {
        state: {
          routingData,
          supplierData,
          componentSource: 'Supplier'
        }
      });
    } catch (error) {
      console.error("Error during submission:", error);
      setValidationError("Error fetching routing records. Please try again.");
    } finally {
      setLoading(false);
    }
  };
 
  if (!supplierData?.data) {
    return (
      <Container className="py-4">
        <Alert variant="danger">
          No supplier data available. Please return to the previous page and try again.
        </Alert>
        <Button variant="primary" onClick={() => navigate("/")}>
          Return to Home
        </Button>
      </Container>
    );
  }
 
  return (
    <Container className="py-4">
      <div className="mb-4">
        <h1 className="h3 text-purple-800 mb-3">
          THE NEIMAN MARCUS GROUP ROUTING GUIDE
        </h1>
        <button
          variant="link"
          className="nav-button"
          onClick={() => navigate("/")}>
        <span class="arrow-icon">←</span>
        <span class="button-text">ROUTING HOME</span>        
        </button>
      </div>
 
      <Card>
        <Card.Body>
          {validationError && (
            <Alert variant="danger" className="mb-4">
              {validationError}
            </Alert>
          )}
 
          <div className="bg-light p-3 mb-4">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Vendor Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={supplierData?.data[0]?.vendor_name || "N/A"}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Supplier Site</Form.Label>
                  <Form.Control
                    type="text"
                    value={supplierData?.data[0]?.supplier_site_id || "N/A"}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Department</Form.Label>
                  <Form.Control
                    type="text"
                    value={supplierData?.data[0]?.dept_code || "N/A"}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
 
          <div className="mb-4">
            <p className="header-description">
              Select the correct Neiman Marcus Group Chain and Verify the 'Ship
              From Zip' shown below is the proper shipping location zip code.
              Modify the zip code if necessary and Click on the 'Submit' button
              to view the General routing information for this Supplier and
              Department combination.
            </p>
          </div>
 
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Chain *</Form.Label>
                  <Form.Select
                    value={formData.chainArea}
                    onChange={(e) => handleInputChange("chainArea", e.target.value)}
                    required
                    disabled={loading}
                  >
                    <option value="">Select Chain</option>
                    {chainOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Brand *</Form.Label>
                  <Form.Select
                    value={formData.brand_id}
                    onChange={(e) => handleInputChange("brand_id", e.target.value)}
                    required
                    disabled={loading || !formData.chainArea}
                  >
                    <option value="">Select Brand</option>
                    {brandOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
 
            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">Ship From Zip *</Form.Label>
              <Form.Control
                type="text"
                value={formData.shipFromZip}
                onChange={(e) => handleInputChange("shipFromZip", e.target.value)}
                required
                placeholder="Enter zip code (e.g., 12345 or 12345-6789)"
                disabled={loading}
              />
            </Form.Group>
 
            <div className="mb-4">
              <Button
                type="submit"
                variant="primary"
                disabled={loading || !!validationError}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>

          <p className="header-description">
            For General Routing and Shipping Instructions please{" "}
            <a href="#" className="header-description-link">
              Click Here
            </a>{" "}
            or call the Neiman Marcus Transportation Department at 469-262-1255
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};
 
export default RoutingGuidePage;