import React from 'react';
import { useLocation } from 'react-router-dom';
import "./StoresAbbrevationPage.css";

const StoresAbbrevationPage = () => {
  const location = useLocation();
  const { allStore, isFromStoreClick } = location.state || {};

  return (
    <div className="stores-container">
        <div className="stores-header">
      <h1>The Neiman Marcus Group Routing Guide Store Listing</h1>
    </div>
      
      {/* Show appropriate heading based on navigation source */}
      <h3>
        {isFromStoreClick 
          ? 'Selected Store Details' 
          : 'All Stores Information'}
      </h3>

      <table className="stores-table">
        <thead>
          <tr>
            <th>Store Number</th>
            <th>Abbreviation</th>
            <th>Store Name</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>ZIP Code</th>
          </tr>
        </thead>
        <tbody>
          {allStore?.map((store) => (
            <tr key={store.store_number}>
              <td>{store.store_number}</td>
              <td>{store.abbreviation}</td>
              <td>{store.name}</td>
              <td>{store.address}</td>
              <td>{store.city}</td>
              <td>{store.state_name}</td>
              <td>{store.zip}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StoresAbbrevationPage;