// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
import { updateAxiosConfig } from './services/AxiosInstance';
import { initializeContentfulClient } from './contentfulClient';
import { ConfigProvider } from "./configContext";

// Function to load config, update axios, and initialize Contentful client
const loadConfig = async () => {
  const origin = window.location.origin;
  const res = await axios.get(`${origin}/static/config.json`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const config = res.data;
  console.log("Config loaded:", config);

  // Update the axios instance with the new config
  updateAxiosConfig(config);

  // Initialize the Contentful client with config values
  initializeContentfulClient(config);

  return config;
};

const rootElement = document.getElementById('root');

// Load configuration first, then render the app
loadConfig()
  .then((config) => {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <ConfigProvider config={config}>
          <Router>
            <App />
          </Router>
        </ConfigProvider>
      </React.StrictMode>
    );
    reportWebVitals();
  })
  .catch((error) => {
    console.error("Error loading configuration:", error);
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <div>Error loading configuration. Please try again later.</div>
      </React.StrictMode>
    );
  });
