
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { poTypesData } from "./components/MockData/PoTypeMockData";

const POInternationalPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { poData,poNumber,type,poType } = location.state || {};
  console.log("podata.........",poData);

  // Sample data for International PO (this can be dynamically fetched from API if needed)
  const internationalPOData = {
    poNumber: "10101010124",
    startShipDate: "2024-08-01",
    cancelDate: "2024-08-30",
    poStatus: "Past Cancel",
    poType: "Import Order",
    chain: "NMD",
    vendor: "TAMILEE INDUSTRIES INC",
    supplierSite: "10003725",
    shipFromZip: "XXXXX",
    routingGuide: "Routing Guide Example",
  };
  const Division = [
    {
      id: 1,
      value: "Neiman Marcus Stores",
      label: "Neiman Marcus Stores",
    },
    {
      id: 3,
      value: "Neiman Marcus Direct",
      label: "Neiman Marcus Direct",
    },
    { id: 2, value: "Bergdorf Goodman", label: "Bergdorf Goodman" },
    { id: 4, value: "Last Call", label: "Last Call" },
  ];
  const getDivisionLabel = (chain, area) => {
    if (chain == 1) {
      if (area == 12) {
        const division = Division.find((div) => div.id == 1);
        return division ? division.label : "N/A";
      }
      if (area == 13) {
        const division = Division.find((div) => div.id == 3);
        return division ? division.label : "N/A";
      }
    }
    
    const division = Division.find((div) => div.id == chain);
    return division ? division.label : "N/A";
  };

  // Function to handle navigation to the Routing Guide page
  const handleViewRoutingGuide = () => {
    navigate("/international/shipping-documents");
  };
  const newCancelDate = new Date(poData.cancel_date).toISOString().split("T")[0];

 //give me a function that will retrive the descrtiption from poTypesData basing on potype that we have from podata.po_type
  const getDescription = (poType) => {  
    const foundType = poTypesData.poTypes.find((item) => item.code === poType);
    return foundType ? foundType.description : "N/A";
  };

  



  return (
    <div className="po-international-page">
      <h3 style={{marginTop:20,marginBottom:50,color:'brown'}}>International PO Details</h3>
      <table className="po-details-table">
        <thead>
          <tr>
            <th>PO Number</th>
            <th>Start Ship Date</th>
            <th>Cancel Date</th>
            <th>PO Status</th>
            <th>PO Type</th>
            <th>Chain</th>
            <th>Vendor</th>
            <th>Supplier Site</th>
            <th>Ship From Zip</th>
            <th>Routing Guide</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{poNumber}</td>
            <td>{internationalPOData.startShipDate}</td>
            <td>{newCancelDate}</td>
            <td>{poData.po_status}</td>
            <td>{getDescription(poData.po_type)}</td>
            <td>{getDivisionLabel(poData?.chain, poData?.area)}</td>
            <td>{poData.vendor_name}</td>
            <td>{poData.supplier_site_id}</td>
            <td>{poData.zip}</td>
            <td>
              {poData.routingGuide}
              <button
               className="routing-guide-btn"
                onClick={handleViewRoutingGuide}
              >
                View International Shipping
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default POInternationalPage;
