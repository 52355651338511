// contentfulClient.js
import * as contentful from "contentful";

let client = null;

export const initializeContentfulClient = (config) => {
  if (config?.CONTENTFUL_SPACE && config?.CONTENTFUL_ACCESS_TOKEN) {
    client = contentful.createClient({
      space: config.CONTENTFUL_SPACE,
      accessToken: config.CONTENTFUL_ACCESS_TOKEN,
    });
  }
};

export const getContentfulClient = () => {
  if (!client) {
    throw new Error("Contentful client not initialized yet.");
  }
  return client;
};

