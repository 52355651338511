export const poTypesData = {
  "poTypes": [
    {
      "description": "EXPRESS ALLOCATION",
      "code": "EA"
    },
    {
      "description": "FASHION",
      "code": "FA"
    },
    {
      "description": "HOLD FOR CONFIRMATION",
      "code": "HC"
    },
    {
      "description": "IMPORT",
      "code": "IM"
    },
    {
      "description": "PACK AND HOLD",
      "code": "PH"
    },
    {
      "description": "REORDER",
      "code": "RE"
    },
    {
      "description": "REPLENISHMENT",
      "code": "RP"
    },
    {
      "description": "SAMPLE",
      "code": "SA"
    },
    {
      "description": "SPECIAL ORDER",
      "code": "SO"
    },
    {
      "description": "TRUNK SHOW",
      "code": "TS"
    },
    {
      "description": "COLLATERAL",
      "code": "CL"
    }
  ]
};