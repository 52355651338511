import axiosInstance from "./AxiosInstance";
 
const fetchRoutingRecordsusingSupplier = async (supplierSiteId,commodityCode,zipcode,chain,area,department_code) => {
  try {
    const response = await axiosInstance.get('/routingRecordsusingSupplierId', {
      params: { supplierSiteId,commodityCode,zipcode,chain,area,department_code },
    });
   
    return response.data;
  } catch (error) {
    console.error("Error checking PO number:", error.response?.data || error.message);
    throw new Error("PO number not found");
  }
};
 
export default fetchRoutingRecordsusingSupplier;