import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Footer from "./components/Footer/Footer";
import AppRouter from "../src/components/AppRouter/AppRouter";
import useContentful from "./hooks/useContentful";
import Loder from "./assets/loder.gif";
import "./App.css";

function App() {
  const pageLocation = useLocation();
  const [childValue, setChildValue] = useState("");
  
  const {
    items: menuItems,
    loading,
    error,
  } = useContentful("mainMenu", { include: 4 });

  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};

  const handleValueChange = (value) => {
    setChildValue(value);
  };

  if (loading) {
    return (
      <div className="preLoder">
        <img src={Loder} alt="Loading..." />
      </div>
    );
  }

  if (error) {
    return <div>Error loading menu: {error.message}</div>;
  }

  return (
    <div className="App">
      <NavigationBar onValueChange={handleValueChange} />
      <div
        className="content-wrapper"
        style={{
          minHeight: `${
            pageLocation.pathname === "/" || pageLocation.pathname === "/alerts"
              ? "0"
              : childValue
          }px`,
        }}
      >
        <div className="test-content">
          <AppRouter menuItems={menuItems} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;