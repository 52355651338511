import axiosInstance from "./AxiosInstance";

const generatePdf = async (payload) => {
  try {
    const response = await axiosInstance({
      method: "PUT",
      url: "/pdfgenerator",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: false,
      data: payload,
    });

    const { pdfBase64 } = JSON.parse(response.data.body);
    return pdfBase64;
  } catch (error) {
    console.error("Error generating PDF:", error);
    throw error;
  }
};

export default generatePdf;
