import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./ZipRoutingDetails.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { checkPONumber } from "../../services/poGet";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import StoreService from "../../services/storeGet";
import { chain } from "lodash";

const Division = [
  {
    id: 1,
    value: "Neiman Marcus Stores",
    label: "Neiman Marcus Stores",
  },
  {
    id: 3,
    value: "Neiman Marcus Direct",
    label: "Neiman Marcus Direct",
  },
  { id: 2, value: "Bergdorf Goodman", label: "Bergdorf Goodman" },
  { id: 4, value: "Last Call", label: "Last Call" },
];

const mockData = [
  {
    id: 1,
    poType: "EXPRESS ALLOCATION",
    code: "EA",
  },
  {
    id: 2,
    poType: "FASHION",
    code: "FA",
  },
  {
    id: 3,
    poType: "HOLD FOR CONFIRMATION",
    code: "HC",
  },
  {
    id: 4,
    poType: "IMPORT",
    code: "IM",
  },
  {
    id: 5,
    poType: "PACK AND HOLD",
    code: "PH",
  },
  {
    id: 6,
    poType: "REORDER",
    code: "RE",
  },
  {
    id: 7,
    poType: "REPLENISHMENT",
    code: "RP",
  },
  {
    id: 8,
    poType: "SAMPLE",
    code: "SA",
  },
  {
    id: 9,
    poType: "SPECIAL ORDER",
    code: "SO",
  },
  {
    id: 10,
    poType: "TRUNK SHOW",
    code: "TS",
  },
  {
    id: 11,
    poType: "COLLATERAL",
    code: "CL",
  },
];

const ZipRoutingDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [poDataResponse, setPoResponse] = useState([]);
  const { routingData, supplierData, componentSource } = location.state || {};
  const [open, setOpen] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false);
  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedStoreNumber, setSelectedStoreNumber] = useState(null);
  const [allStore, setAllStore] = useState([]);

  // Safe value extraction function
  const safeGet = (obj, key, defaultValue = "N/A") => {
    return obj && obj[key] ? obj[key] : defaultValue;
  };

  const poDetailsResponse = async (poNumber) => {
    try {
      const response = await checkPONumber(poNumber);
      setPoResponse(response);
      return response;
    } catch (error) {
      console.error("error checking po number", error);
    }
  };

  const getStoreData = async (storeNumber) => {
    console.log("storeNumber inside getStoreData function", storeNumber);

    setLoading(true);
    try {
      console.log(
        "Fetching store data for store number inside try block:",
        storeNumber
      );
      const stores = await StoreService.fetchStore(
        1,
        100,
        "name",
        "asc",
        storeNumber
      );
      const selectedStore = stores.data[0]; // assuming only one store is returned

      // Set the store data
      setStoreData(selectedStore || null);
    } catch (error) {
      console.error("Error fetching store data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAllStoreData = async () => {
    setLoading(true);
    try {
      console.log(
        "Fetching store data for store number inside try block:",
        storeNumber
      );
      const allstores = await StoreService.fetchStore(1, 100, "name", "asc");
      console.log("Fetched stores:", allstores);
      setAllStore(allstores);
    } catch (error) {
      console.error("Error fetching store data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (routingData) {
      poDetailsResponse(safeGet(routingData, "poNumber"));
    }
    getAllStoreData();
    return () => setPoResponse([]);
  }, [routingData]);

  useEffect(() => {
    if (storeOpen && selectedStoreNumber) {
      getStoreData(selectedStoreNumber);
    }
  }, [storeOpen, selectedStoreNumber]);

  if (!routingData) {
    return <p>No routing data available.</p>;
  }

  const {
    poNumber,
    vendorName,
    supplierSite,
    department,
    shipFromZip,
    startShipDate,
    cancelDate,
    poType,
    poStatus,
    routingRecords = [], // Provide default empty array
    poExceptionDetails,
    brand,
    chainNumber,
    zip,
  } = routingData;

  // if (!Array.isArray(routingRecords) || routingRecords.length === 0) {
  //   NotificationManager.info("No routing records available.", "Info", 3000);
  // }


  const firstExceptionDetail = Array.isArray(poExceptionDetails)
    ? poExceptionDetails[0]
    : poExceptionDetails;

  const storeNumber = firstExceptionDetail?.store_list;
  const poData = poDataResponse?.data?.[0] || {};

  const handleClickOpen = (instruction) => {
    setSelectedInstruction(instruction);
    setOpen(true);
  };
  const handleModifyZipClick = () => {
    if (componentSource === "Supplier") {
      navigate("/RoutingGuideusingSupplier", {
        state: {
          supplierData,
        },
      });
    } else if (componentSource === "RoutingGuideUsingPo") {
      navigate("/po-routing-guide", {
        state: {
          poNumber,
          poData: poDataResponse,
        },
      });
    } else {
      navigate(-1); // Default: Go back to the previous page
    }
  };

  const handleClose = () => {
    setStoreOpen(false);
    setSelectedInstruction(null);
    setOpen(false);
  };

  const getDivisionLabel = (chain, area) => {
    console.log("chain", chain);
    console.log("area", area);

    // Special case handling for chain 1
    if (chain == 1) {
      if (area == 12) {
        const division = Division.find((div) => div.id == 1);
        return division ? division.label : "N/A";
      }
      if (area == 13) {
        const division = Division.find((div) => div.id == 3);
        return division ? division.label : "N/A";
      }
    }

    // Default case - original logic
    const division = Division.find((div) => div.id == chain);
    return division ? division.label : "N/A";
  };

  const getPoTypeLabel = (poType) => {
    console.log("poType", poType);
    const poTypeLabel = mockData.find((type) => type.code == poType);
    return poTypeLabel ? poTypeLabel.poType : "N/A";
  };

  const getDisplayValue = (instruction) => {
    const shipTo = instruction.consolidator_name.toLowerCase();

    if (shipTo === "direct to dc") {
      return (
        <span
          style={{
            color: "#0066cc",
            cursor: "pointer",
            textDecoration: "none", // No underline by default
          }}
          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")} // Underline on hover
          onMouseLeave={(e) => (e.target.style.textDecoration = "none")} // Remove underline on hover out
          onClick={(e) => {
            e.stopPropagation();
            handleClickOpen(instruction);
          }}
        >
          {instruction.name}
        </span>
      );
    } else if (shipTo === "direct to store") {
      return "Direct to Store";
    } else {
      return (
        <>
          {instruction.name}
          {" C/o "}
          <span
            style={{
              color: "#0066cc",
              cursor: "pointer",
              textDecoration: "none", // No underline by default
            }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")} // Underline on hover
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")} // Remove underline on hover out
            onClick={(e) => {
              e.stopPropagation();
              handleConsolidatorClick(instruction);
            }}
          >
            {instruction.consolidator_name}
          </span>
        </>
      );
    }
  };

  const handleConsolidatorClick = (instruction) => {
    setSelectedInstruction(instruction);
    console.log("handleConsolidatorClick", instruction);
    setOpen(true);
  };

  const handleOpenDialog = () => {
    setStoreOpen(true);
    getStoreData(storeNumber); // Trigger the API call when dialog opens
  };

  const handleStoreClick = async (storeNumbers) => {
    console.log("storeNumbers", storeNumbers);

    if (storeNumbers.toLowerCase().includes("all ecdc stores")) {
      console.log("Filtering ECDC stores from existing data");

      // Filter and sort stores in ascending order based on store_number
      const ecdcStores = allStore.data
        ?.filter((store) => store.dc_id === 1)
        .sort((a, b) => a.store_number - b.store_number);

      navigate("/stores-abbreviation", {
        state: {
          allStore: ecdcStores,
          isFromStoreClick: true,
          isECDC: true,
        },
      });

      return;
    }

    // Original logic for specific store numbers
    const storeList = storeNumbers.split(",").map((s) => s.trim());
    const relevantStores = allStore.data
      ?.filter((store) => storeList.includes(store.store_number.toString()))
      .sort((a, b) => a.store_number - b.store_number); // Sorting in ascending order

    navigate("/stores-abbreviation", {
      state: {
        allStore: relevantStores,
        isFromStoreClick: true,
      },
    });
  };

  return (
    <div className="routing-container">
      <NotificationContainer></NotificationContainer>

      <div>
        <h1>The Neiman Marcus Group Routing Guide</h1>

        {/* Navigation Buttons */}
        <div className="navigation-buttons">
          <Link to="/" className="nav-button">
            <span className="arrow">&larr;</span>
            <span className="button-text">ROUTING HOME</span>
          </Link>

          <button onClick={handleModifyZipClick} className="nav-button">
            <span className="arrow">&larr;</span>
            <span className="button-text">MODIFY ZIP</span>
          </button>
        </div>

        <p>
          <b>
            * The PO information below is shown with the new Supplier site
            number which replaces the Duns Number, the new four
            <br />
            digit Department, Distribution Center, and Store numbers.
          </b>
        </p>

        {firstExceptionDetail && (
          <>
            <h6>
              Purchase Order Exception Routing for specific stores is listed
              below. Click on the Store Number link in the Ship To field to view
              the Store Ship-To address:
            </h6>

            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginBottom: "20px",
                borderSpacing: "0 10px", // Add vertical spacing between rows
              }}
            >
              <thead>
                <tr>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    {" "}
                    PO Number
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Supplier
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Supplier Site
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Department
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                    {safeGet(firstExceptionDetail, "po_number", poNumber)}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                    {safeGet(firstExceptionDetail, "vendor_name", vendorName)}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                    {safeGet(
                      firstExceptionDetail,
                      "supplier_site_id",
                      supplierSite
                    )}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                    {safeGet(
                      firstExceptionDetail,
                      "department_number",
                      department
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {poExceptionDetails &&
          Array.isArray(poExceptionDetails) &&
          poExceptionDetails.length > 0 && (
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginBottom: "20px",
                borderSpacing: "0 10px",
              }}
            >
              <thead>
                <tr>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Ship To
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Status
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Carrier
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Bill To Account No
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Billing Reference No
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Service Level
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                    Contact
                  </th>
                </tr>
              </thead>
              <tbody>
                {poExceptionDetails.map((exception, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "20px",
                        border: "1px solid #ccc",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => handleOpenDialog(exception.store_list)}
                    >
                      {safeGet(exception, "store_list", "N/A")}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                      {safeGet(exception, "status")}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                      {safeGet(exception, "scac_name", "N/A")}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                      {safeGet(exception, "bill_account_number")}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                      {safeGet(exception, "expense_number")}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                      {safeGet(exception, "service_level", "N/A")}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                      {safeGet(exception, "phone", "")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        <Dialog
          open={storeOpen}
          onClose={() => setStoreOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialog-container"
        >
          <DialogTitle id="alert-dialog-title" className="dialog-title">
            Store Data
          </DialogTitle>
          <DialogContent className="dialog-content">
            <DialogContentText id="alert-dialog-description">
              {loading ? (
                <p>Loading store details...</p>
              ) : storeData ? (
                <ul>
                  <li>Store Name: {storeData.name || "N/A"}</li>
                  <li>State: {storeData.state_name || "N/A"}</li>
                  <li>Address: {storeData.address || "N/A"}</li>
                  <li>City: {storeData.city || "N/A"}</li>
                  <li>Zip Code: {storeData.zip || "N/A"}</li>
                </ul>
              ) : (
                <p>No store data found.</p>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setStoreOpen(false) && setStoreData(null)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <p className="header-description">
          * Note: If Status is "DENIED" please ship via standard routing
          instructions below or contact your buyer!
        </p>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginBottom: "20px",
            borderSpacing: "0 10px", // Add vertical spacing between rows
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Supplier
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                PO Number
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Ship From Zip
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Start Ship Date
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Cancel Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {vendorName}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {poNumber}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {shipFromZip}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {startShipDate && !isNaN(Date.parse(startShipDate))
                  ? new Date(startShipDate).toISOString().split("T")[0]
                  : ""}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {cancelDate && !isNaN(Date.parse(cancelDate))
                  ? new Date(cancelDate).toISOString().split("T")[0]
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginBottom: "20px",
            borderSpacing: "0 10px", // Add vertical spacing between rows
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Supplier Site
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Brand
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Chain
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Department
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                PO Type
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                PO Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {supplierSite || "N/A"}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {poData.brand_id || brand || "N/A"}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {getDivisionLabel(poData.chain || chainNumber, poData.area)}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {department || "N/A"}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {getPoTypeLabel(poType)}
              </td>
              <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                {safeGet(firstExceptionDetail, "po_status", poStatus)}
              </td>
            </tr>
          </tbody>
        </table>

        {routingRecords.length > 0 ? (
  <table style={{
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: "0 10px",
  }}>
          <thead>
            <tr>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Ship To
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc", width: "26%" }}>
                For Stores
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                When Shipping
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Carrier
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                Service Level
              </th>
              <th style={{ padding: "10px", border: "1px solid #ccc" }}>
                ASN DC Location
              </th>
              {/* <th style={{ padding: "10px", border: "1px solid #ccc" }}>Phone</th> */}
            </tr>
          </thead>
          <tbody>
            {routingRecords.map((instruction, index) => (
              <tr key={index}>
                <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                  {instruction.name ? (
                    <span style={{ color: "BLACK" }}>
                      {getDisplayValue(instruction)}
                    </span>
                  ) : (
                    instruction.consolidator_name
                  )}
                </td>

                <td
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: instruction.store ? "#0066cc" : "inherit",
                  }}
                  onClick={() =>
                    instruction.store
                      ? handleStoreClick(instruction.store)
                      : null
                  }
                >
                  {instruction.store}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                  {instruction.ship_message}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                  {instruction.link ? (
                    <a
                      href={instruction.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {instruction.carrier}
                    </a>
                  ) : (
                    `${instruction.carrier_name} ${instruction.contact}`
                  )}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                  {instruction.service_level_name}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ccc" }}>
                  {instruction.name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        ) : (
          <p>No standard routing records available for this PO.</p>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: "customDialogPaper",
            container: "customDialogContainer",
          }}
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Ship to DC Care of"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {selectedInstruction && (
                <>
                  {selectedInstruction.consolidator_name.toLowerCase() ===
                    "direct to dc" ||
                  selectedInstruction.consolidator_name.toLowerCase() ===
                    "direct to store" ? (
                    // Form for "direct to dc" or "direct to store"
                    <>
                      <li>Consolidator : {selectedInstruction.name}</li>
                      <li>Final Address : {selectedInstruction.address}</li>
                      <li>City : {selectedInstruction.city}</li>
                      <li>Zip Code : {selectedInstruction.zip}</li>
                    </>
                  ) : (
                    // Form for other cases
                    <>
                      <li>
                        Consolidator : {selectedInstruction.consolidator_name}
                      </li>
                      <li>Final Address : {selectedInstruction.consolidator_address}</li>
                      <li>City :{selectedInstruction.consolidator_city}</li>
                      <li>State : {selectedInstruction.consolidator_state} </li>
                      <li>Zip Code : {selectedInstruction.consolidator_zip} </li>
                    </>
                  )}
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>

        <div>
          <p className="header-description">
            *Shipments over 8000lbs. and/or shipments 749 cube or more
          </p>
          <h6>
            Please click on the underlined locations in the Routing Guide to see
            the Ship to Address or additional information. Please click on the
            underlined carrier names in the Routing Guide to go to the carrier
            website.
          </h6>
          <p className="header-description">
            For General Routing and Shipping Instructions please
            <Link
              to="/domestic/transportation/general-routing-and-shipping-instructions"
              style={{ color: "blue", textDecoration: "none" }}
            >
              (Click Here)
            </Link>{" "}
            or call the Neiman Marcus Transportation Department at 469-262-1255
          </p>
        </div>
      </div>
    </div>
  );
};

export default ZipRoutingDetailsPage;
