import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import client from "../../contentfulClient";
import { getContentfulClient } from "../../contentfulClient";
import "./Footer.css";

const Footer = () => {
  const client = getContentfulClient();
  const [footerImages, setFooterImages] = useState([]);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await client.getEntries({
          content_type: "footer", // Ensure this matches your content type ID
          select: "fields.image", // Adjust according to your fields
        });

        if (response.items.length > 0) {
          const item = response.items[0];
          const images = item.fields.image || []; // Access the images field
          setFooterImages(images);
        }
      } catch (error) {
        console.error("Error fetching footer data:", error);
      }
    };

    fetchFooterData();
  }, []);

  return (
    <>
      <div className="footer_logos">
        <div className="footer-images">
          {footerImages.map((image, index) => (
            <img
              key={index}
              src={image.fields.file.url}
              alt={`Footer image ${index}`}
            />
          ))}
        </div>
      </div>
      <div className="footer">
        <div className="footer-links">
          <p>
            <Link to="/privacy"> PRIVACY </Link>{" "} |{" "}
            <Link to="/codeOfConduct"> CODE OF CONDUCT </Link>{" "}|{" "}
            <Link to="/poLegalTerms"> PO LEGAL TERMS </Link>
          </p>
          <p style={{ color: 'white',fontfamily: 'sans-serif', fontSize: '14px'}}>
            © Copyright 2024, The Neiman Marcus Group, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
