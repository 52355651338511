let routesList = [];

export const addRoute = (route) => {
  routesList.push(route);
};

export const getPath = (endPath, parentMenu) => {
  const formattedEndPath = `/${endPath}`;

  // Find all matching routes
  const matchingRoutes = routesList.filter((route) =>
    route.endsWith(formattedEndPath)
  );

  // If there are multiple matches, filter by parentMenu
  if (matchingRoutes.length > 1) {
    const filteredRoutes = matchingRoutes.filter((route) => {
      // Assuming the parentMenu is part of the route's structure
      // Adjust the condition based on how your routes are structured
      return route.includes(parentMenu);
    });

    return filteredRoutes.length > 0 ? filteredRoutes[0] : null;
  }

  // Return the single match or null if not found
  return matchingRoutes.length === 1 ? matchingRoutes[0] : null;
};