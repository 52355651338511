import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { checkPONumber, checkRoutingBySupplier } from "../../services/poGet";
import loaderGif from "../../assets/loder.gif"; // import the loader image
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const MerchandiseRoutingForm = () => {
  const [routingPO, setRoutingPO] = useState("");
  const [supplierSite, setSupplierSite] = useState("");
  const [department, setDepartment] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();

  const handleRoutingSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true); // Start loading

    try {
      // Ensure either PO Number or Supplier Site and Department are provided
      if (!routingPO.trim() && (!supplierSite.trim() || !department.trim())) {
        if (!supplierSite.trim() && department.trim()) {
          setError("Supplier Site ID is required when Department is provided.");
        } else if (supplierSite.trim() && !department.trim()) {
          setError("Department is required when Supplier Site ID is provided.");
        } else {
          setError("Either PO Number or both Supplier Site ID and Department must be provided.");
        }
        setLoading(false); // Stop loading since validation failed
        return;
      }
  
      // Check if PO number is provided
      if (routingPO.trim()) {
        console.log("PO Number Submitted:", routingPO);
        const encodedPONumber = encodeURIComponent(routingPO.trim());
        const poData = await checkPONumber(encodedPONumber);
        
        if (!poData || poData.data.length === 0) {
            setError("Please enter a valid PO Number."); // Invalid PO number
        } else {
            const poType = poData.data[0].po_type;
            
            // Check if po_type is IO or DS
            if (poType === "IM" || poType === "DS") {
                navigate(`/po-international`, {
                    state: {
                        poData: poData.data[0],
                        poNumber: encodedPONumber,
                        // type: "international",
                    }
                });
            } else {
                
                navigate(`/po-routing-guide`, {
                    state: {
                        poData: poData,
                        poNumber: encodedPONumber,
                        type: "domestic",
                    }
                });
            }
        }
    }
    
      // Check if both supplier site and department are provided
      else if (supplierSite.trim() && department.trim()) {
        console.log("Fetching routing by supplier site and department");
        const routingData = await checkRoutingBySupplier(
          supplierSite.trim(),
          department.trim()
        );
        console.log("Routing Data searching for comkodity codeeee:", routingData);
  
        if (!routingData || routingData.totalRecords === 0) {
          setError(
            "Invalid Supplier Site ID or Department. Please provide correct details."
          );
        } else {
          NotificationManager.info(
            "Routing data found for the specified Supplier Site and Department"
          );
          navigate("/RoutingGuideusingSupplier", {
            state: {
              supplierData: routingData,
              supplierSite: supplierSite,
              department: department,
              commodityCode: routingData.data[0].commodity_code_id || "N/A" 
            },
          });
        }
      }
    } catch (error) {
      console.error("Error fetching routing data:", error.message);
      setError(
        "An error occurred while fetching data. Please verify the Supplier Site ID and Department."
      );
    } finally {
      setLoading(false); // End loading
      setRoutingPO("");
      setSupplierSite("");
      setDepartment("");
    }
  };

  return (
    <Col md={4}>
      <div className="forms-column">
        <NotificationContainer></NotificationContainer>
        <h3>Merchandise Routing Guides</h3>
        <form onSubmit={handleRoutingSubmit}>
          {error && <div className="error-message">{error}</div>}

          <div className="form-field">
            <label htmlFor="poNumber">PO Number</label>
            <input
              type="text"
              id="poNumber"
              value={routingPO}
              onChange={(e) => setRoutingPO(e.target.value)}
              placeholder="Enter PO Number"
            />
          </div>

          <div className="or-divider">OR</div>

          <div className="form-field">
            <label htmlFor="supplierSite">Supplier Site</label>
            <input
              type="text"
              id="supplierSite"
              value={supplierSite}
              onChange={(e) => setSupplierSite(e.target.value)}
              placeholder="Enter Supplier Site"
            />
          </div>

          <div className="form-field">
            <label htmlFor="department">Department</label>
            <input
              type="text"
              id="department"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              placeholder="Enter Department"
            />
          </div>

          <div className="submit-button-container">
            <label></label>
            <button type="submit" disabled={loading}>
              Submit
            </button>
          </div>
        </form>

        {loading && (
          <div className="loader-container">
            <img src={loaderGif} alt="Loading..." />
          </div>
        )}
      </div>
    </Col>
  );
};

export default MerchandiseRoutingForm;
