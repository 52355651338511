import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPONumber } from "../../services/poGet";
import fetchRoutingRecords from "../../services/routingRecords";
import LoadingIndicator from "../../assets/loder.gif"; // Import the loader image

function POVerificationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [poNumbers, setPoNumbers] = useState("");
  const [poError, setPoError] = useState("");
  const [poSearchResults, setPoSearchResults] = useState([]);
  const [loading, setLoading] = useState(false); // Added loading state
  const [zipCodes, setZipCodes] = useState({});
  const [fetchedPoDetails, setFetchedPoDetails] = useState([]);
  const { poSearchResultsMain, poNumbersMain } = location.state || {};
  const [poVerificationloading, setPoVerificationLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const poNumbersFromUrl = searchParams.get("poNumbers");
    if (poNumbersFromUrl) {
      setPoNumbers(poNumbersFromUrl.split(",").join("\n"));
    }
  }, [location.search]);

  const handleVerificationSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!poNumbers.trim()) {
      setPoError("This field is required.");
      return;
    }

    const poNumbersArray = Array.from(
      new Set(
        poNumbers
          .split(/\n|,/)
          .map((po) => po.trim())
          .filter((po) => po)
      )
    );

      const hasNonNumbers = poNumbersArray.some(po => !/^\d+$/.test(po));
    if (hasNonNumbers) {
      setPoError("Only numbers are allowed");
      return;
    }
    if (poNumbersArray.length > 20) {
      setPoError("You can enter up to 20 PO numbers.");
      return;
    }

    setPoVerificationLoading(true);
    try {
      const fetchedPoDetails = await Promise.all(
        poNumbersArray.map(async (po) => {
          try {
            const response = await checkPONumber(po);
            const poData = response?.data?.[0];

            // If PO data exists, return detailed PO information
            if (poData) {
              return {
                po_number: po,
                start_ship_date: poData.start_ship_date,
                cancel_date: poData.cancel_date,
                po_status: poData.po_status,
                po_type: poData.po_type,
                chain: poData.chain,
                vendor_name: poData.vendor_name,
                supplier_site_id: poData.supplier_site_id,
                ship_from_zip: poData.ship_from_zip,
              };
            }

            // If no data found, return with error
            return {
              po_number: po,
              error: "PO Not Found.",
            };
          } catch (error) {
            console.error("Error fetching PO details for:", po, error);
            return {
              po_number: po,
              error: "Failed to fetch details",
            };
          }
          finally {
            setPoVerificationLoading(false);
          }
        })
      );

      // Filter out entries with errors if needed
      const validPoDetails = fetchedPoDetails.filter((po) => !po.error);

      if (validPoDetails.length === 0) {
        setPoError("All entered PO numbers are invalid.");
        setPoSearchResults([]);
      } else {
        setPoSearchResults(validPoDetails);
        setPoError("");
      }
    } catch (error) {
      console.error("Error during PO search:", error);
      setPoError("Error fetching PO details. Please try again.");
    } finally {
      setLoading(false); // Hide loader after fetching
    }
  };

  // Handle clear button click
  const handleClear = () => {
    setPoNumbers("");
    setPoError("");
    setPoSearchResults([]);
    setZipCodes({}); // Clear all ZIP codes
  };

  // Handle the routing guide button click
  const handleRoutingGuideClick = async (po_number,zipCodes) => {
    try {
      const firstPO = poNumbers.split("\n").map((po) => po.trim())[0];
      if (!firstPO) return;

      const routingDataResponse = await fetchRoutingRecords(po_number,zipCodes);
      const poResponse = await checkPONumber(po_number);
      const poData = poResponse?.data?.[0] || {};

      // Prepare routing data
      const routingData = {
        poNumber: po_number,
        vendorName: poData.vendor_name || "Unknown Vendor",
        supplierSite: poData.supplier_site_id || "N/A",
        department: poData.department_id || "N/A",
        shipFromZip: poData.ship_from_zip || "N/A",
        startShipDate: poData.start_ship_date || "N/A",
        cancelDate: poData.cancel_date || "N/A",
        routingRecords: routingDataResponse?.data || [],
      };

      navigate("/routing-details", { state: { routingData } });
    } catch {
      setPoError("Error fetching routing records.");
    }
  };

  // Handle ZIP code change
  const handleZipCodeChange = (po, value) => {
    setZipCodes((prevZipCodes) => ({
      ...prevZipCodes,
      [po]: value,
    }));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Only allow numbers, commas, and newlines
    if (value === '' || /^[0-9,\n]+$/.test(value)) {
      setPoNumbers(value);
      if (value.trim()) setPoError("");
    } else {
      setPoError("Only numbers are allowed");
    }
  };

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "20px" }}>
      <div>
        <h2>The Neiman Marcus Group PO Verification</h2>
        <p>
          This verification can be used to determine the status of your purchase
          orders prior to shipping. Please contact your buyer with any questions
          about your purchase order or if your purchase order is not found.
          Purchase Order information is updated nightly.
        </p>
        <p>
          Note: If any purchase order in a shipment is invalid, the entire
          shipment will be refused from the carrier.
        </p>
        <p>Enter up to 20 PO's, one per line, and click submit.</p>
        <form onSubmit={handleVerificationSubmit}>
          {poError && <span className="error-message">{poError}</span>}
          <div className="form-field" style={{ position: "relative" }}>
            <label
              htmlFor="po-number"
              style={{
                position: "relative",
                display: "block",
                marginBottom: "120px",
              }}
            >
              PO Numbersbn bn bn
            </label>
            <div
              style={{
                width: "100%",
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <textarea
                id="po-number"
                rows="5"
                placeholder="Enter up to 20 PO Numbers, one per line."
                value={poNumbersMain}
                onChange={handleInputChange}
                // onChange={(e) => {
                //   setPoNumbers(e.target.value);
                //   if (e.target.value.trim()) setPoError("");
                // }}
                className={poError ? "error" : ""}
                style={{ width: "300px", resize: "both", paddingTop: "20px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <button
              type="submit"
              style={{
                padding: "10px 15px",
                backgroundColor: "blue",
                color: "white",
                borderRadius: "3px",
              }}
              //disabled={loading} // Disable button while loading
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleClear}
              style={{
                padding: "10px 15px",
                backgroundColor: "blue",
                color: "white",
                borderRadius: "3px",
              }}
            >
              Clear
            </button>
          </div>
        </form>

        {/* Display loader while fetching PO details */}
        {loading && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <img src={LoadingIndicator} alt="Loading..." />
          </div>
        )}

        {/* Display PO Search Results */}
        {poSearchResultsMain.length > 0 && (
          <div>
            <h3 style={{ marginTop: "50px" }}>PO Search Results</h3>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    PO Number
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Start Ship Date
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Cancel Date
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    PO Status
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Shipment Type
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Chain
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    VendorName
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Supplier Site
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Ship From ZIP
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "10px" }}>
                    Routing Guide
                  </th>
                </tr>
              </thead>
              <tbody>
                {poSearchResultsMain.map((poDetails) => (
                  <tr key={poDetails.po_number}>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.po_number}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.start_ship_date}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.cancel_date}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.po_status}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.po_type}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.chain}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.vendor_name}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.supplier_site_id}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.ship_from_zip}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {/* Conditionally render editable ZIP code input */}
                      {poDetails.po_status === "Approved" &&
                      poDetails.po_type === "Type A" ? (
                        <input
                          type="text"
                          value={
                            zipCodes[poDetails.po_number] ||
                            poDetails.ship_from_zip
                          } // Use entered ZIP code or existing ZIP
                          onChange={(e) =>
                            handleZipCodeChange(
                              poDetails.po_number,
                              e.target.value
                            )
                          } // Update ZIP code on change
                          placeholder="Enter ZIP"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        poDetails.ship_from_zip // Display ZIP code if not editable
                      )}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                      {poDetails.po_status === "Closed" &&
                      poDetails.po_type === "international" ? (
                        <span>
                          Routing Guide link is only available for Approved PO
                          Numbers.
                        </span>
                      ) : poDetails.po_status === "Pending" &&
                        poDetails.po_type === "Type C" ? (
                        <button
                          style={{
                            backgroundColor: "#007BFF", // Blue color
                            color: "white", // Text color
                            padding: "10px 15px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleRoutingGuideClick(poDetails.po_number,zipCodes)
                          }
                        >
                          ViewRoutingGuide
                        </button>
                      ) : (
                        <span>
                          Routing Guide link is only available for Approved PO
                          Numbers
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default POVerificationPage;
